import { createContext, FC, ReactNode, useContext } from 'react';
import { TABLE_DATA } from '../ui/Table/Table';

export interface FilterClaim {
	topic: string;
	status: { id: string; name: string };
	priority: { id: string; name: string };
	sender: { id: string; name: string };
}

interface Context {
	claims: TABLE_DATA[];
	filter?: FilterClaim;
	updatedFilter?: (filteredClaims: FilterClaim) => void;
}

interface Props extends Context {
	children: ReactNode;
}

const ClaimContext = createContext<Context | null>(null);

export const ClaimContextProvider: FC<Props> = props => {
	const { claims, updatedFilter, filter, children } = props;

	return (
		<ClaimContext.Provider
			value={{
				claims,
				filter,
				updatedFilter,
			}}
		>
			{children}
		</ClaimContext.Provider>
	);
};

export const useClaimContext = () => {
	const context = useContext(ClaimContext);

	if (!context) {
		throw new Error('useClaimContext must be used within ClaimContext');
	}

	return context;
};
