import { FC } from 'react';
import { useGetDepartmentFindQuery } from 'src/app/redux/queries/department-service/department_serviceAPI';
import { actionsEventCalendar } from 'src/app/redux/state/event-calendar/slice';
import { UserListItem } from 'src/app/redux/state/event-calendar/types';
import { useAppDispatch, useAppSelector } from 'src/app/redux/utils';
import { AddUsersMC } from 'src/features/_modal_contents/AddUsersMC';

interface Props {
	toggleModal: () => void;
	allUsers: UserListItem[];
	createEventUsers?: (ids: string[]) => void;
}

export const AddUserMCContainer: FC<Props> = props => {
	const {
		toggleModal, //
		allUsers,
		createEventUsers,
	} = props;

	// * Selectors
	const selectedUsersInfo = useAppSelector(state => state.event_calendar.event_form.selectedUsersInfo);

	// * Actions
	const dispatch = useAppDispatch();
	const { setSelectedUsersInfo } = actionsEventCalendar;

	// * API
	const { data: departmentData, isLoading: isLoadingDepartments } = useGetDepartmentFindQuery({
		skipcount: 0,
		takecount: 1000,
	});

	const allDepartments = departmentData?.body ?? [];

	// * Selected users
	const setSelectedUsers = (userIds: string[]) => {
		dispatch(setSelectedUsersInfo([...selectedUsersInfo.users, ...allUsers.filter(user => userIds.includes(user.id))]));
		toggleModal();
	};

	// * Render
	return (
		<AddUsersMC
			title="Пригласить"
			allUsers={allUsers.filter(user => !selectedUsersInfo.users.find(existingUser => existingUser.id === user.id))}
			allDepartments={allDepartments}
			setSelectedUsers={setSelectedUsers}
			isLoading={isLoadingDepartments} // Мы сохраняем в Редакс, загрузки нет, но показывается загрузка departmentTree
			toggleModal={toggleModal}
			createUsers={createEventUsers}
			primaryText="Пригласить"
		/>
	);
};
