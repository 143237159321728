import React from 'react';
import { usePatchEventUserEditMutation, usePostEventUserCreateMutation } from 'src/app/redux/queries/event-service/event_serviceAPI';
import { EventResponse } from 'src/app/redux/queries/event-service/types/resTypes';
import { useAppSelector } from 'src/app/redux/utils';
import { getCategoryInfo } from 'src/pages/event_calendar/ui/EventCalendarPage/lib/getCategoryInfo/getCategoryInfo';
import { ReactComponent as LockSVG } from 'src/shared/assets/svg_icons/action/lock.svg';
import { ReactComponent as UnlockSVG } from 'src/shared/assets/svg_icons/action/unlock.svg';
import { useRights } from 'src/shared/hooks/useRights';
import { dateToFullRuDateString, getIsPastDate, isoRemoveSeconds } from 'src/shared/lib/date';
import { Button } from 'src/shared/ui/_buttons/Button';
import { ChipCategory } from 'src/shared/ui/_chips/ChipCategory/ChipCategory';
import { Heading } from 'src/shared/ui/Heading';
import { EventDescription } from '../EventDescription';
import { EventDropDownMenu } from '../EventDropDownMenu/EventDropDownMenu';
import { EventFiles } from '../EventFiles/EventFiles';
import s from './EventInfoCard.module.scss';

interface Props {
	eventInfo: EventResponse;
}

export const EventInfoCard: React.FC<Props> = props => {
	const { eventInfo } = props;
	const isAdmin = useRights();

	// * Selectors
	const selectedUsersInfo = useAppSelector(state => state.event_calendar.event_form.selectedUsersInfo).users;
	const activeUserId = useAppSelector(state => state.user_service.user.userInfo)?.user.id;

	const eventUser = selectedUsersInfo.find(item => item.id === activeUserId);
	const isParticipant = !!eventUser;

	// - не архивное && не прошедшее && закрытое (отказ от участия) && открытое
	const isShowButton =
		eventInfo.isActive &&
		!(getIsPastDate(eventInfo.date) && (eventInfo.endDate ? getIsPastDate(eventInfo.endDate) : true)) &&
		((isParticipant && !!eventInfo.access) || !eventInfo.access);

	// * API
	const [createEventUsers] = usePostEventUserCreateMutation();
	const [editEventUser] = usePatchEventUserEditMutation();

	const onCreateEventUser = () => {
		createEventUsers({
			createEventUserRequest: {
				eventId: eventInfo.id,
				users: [{ userId: activeUserId as string }],
			},
		})
			.unwrap()
			.catch(e => console.log(e));
	};

	const onDeleteEventUsers = () => {
		editEventUser({
			eventUserId: eventUser?.eventUserId as string,
			body: [
				{
					op: 'replace',
					value: 'Discarded',
					path: '/Status',
				},
			],
		})
			.unwrap()
			.catch(e => console.log(e));
	};

	const handleAddUser = () => (isParticipant ? onDeleteEventUsers() : onCreateEventUser());

	// * Render
	return (
		<div className={s.container}>
			<div>
				<div className={s.header}>
					<div>
						{!eventInfo.access ? <UnlockSVG /> : <LockSVG />}

						<Heading
							level={3}
							className={s.header__title}
						>
							{eventInfo.name}
						</Heading>
					</div>

					<div>
						{isShowButton && (
							<Button
								variant={isParticipant ? 'secondary' : 'primary'}
								onClick={handleAddUser}
							>
								{isParticipant ? 'Отказаться от участия' : 'Присоединиться'}
							</Button>
						)}
						{isAdmin && (
							<EventDropDownMenu
								eventId={eventInfo.id}
								isActive={eventInfo.isActive}
								isPast={getIsPastDate(eventInfo.endDate)}
							/>
						)}
					</div>
				</div>

				{eventInfo.eventCategories && <ChipCategory {...getCategoryInfo(eventInfo.eventCategories)} />}
			</div>

			<EventDescription
				start={`${dateToFullRuDateString(new Date(eventInfo.date))} ${isoRemoveSeconds(eventInfo.date)}`}
				end={eventInfo.endDate ? `${dateToFullRuDateString(new Date(eventInfo.endDate))} ${isoRemoveSeconds(eventInfo.endDate)}` : '-'}
				participants={selectedUsersInfo.length || 0}
				place={eventInfo.address}
				description={eventInfo.description}
				eventId={eventInfo.id}
			/>

			<EventFiles
				images={eventInfo.eventImages || []}
				files={eventInfo.eventFiles || []}
			/>
		</div>
	);
};
