import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { PageDecorator } from 'src/app/decorators/page_decorators/PageDecorator/PageDecorator';
import { event_serviceAPI, useGetEventUserFindQuery } from 'src/app/redux/queries/event-service/event_serviceAPI';
import { useGetUserFindQuery } from 'src/app/redux/queries/user-service/user_serviceAPI';
import { actionsEventCalendar } from 'src/app/redux/state/event-calendar/slice';
import { UserListItem } from 'src/app/redux/state/event-calendar/types';
import { useAppDispatch } from 'src/app/redux/utils';
import { PageTitle } from 'src/entities/PageTitle/PageTitle';
import { createFullName } from 'src/shared/lib/string';
import { ConditionalRendering } from 'src/shared/providers';
import { EventComments } from '../EventComments';
import { EventInfoCard } from '../EventInfoCard';

export const EventDetailsPage: React.FC = () => {
	// * Routing
	const { eventId } = useParams() as { eventId: string };

	// * Actions
	const dispatch = useAppDispatch();
	const { setSelectedUsersInfo } = actionsEventCalendar;

	// * API
	const {
		data: eventData,
		isLoading: eventLoading,
		refetch,
	} = event_serviceAPI.useGetEventGetQuery({
		eventId,
		includeUsers: true,
		includeCategories: true,
		includeImages: true,
		includeFiles: true,
		includeComments: true,
	});

	const { data, isLoading: userLoading } = useGetUserFindQuery({
		skipcount: 0,
		takecount: 1000,
		includepositions: true,
		includedepartments: true,
		isactive: true,
		includecurrentavatar: true,
	});

	const { data: eventUsersData } = useGetEventUserFindQuery({
		skipcount: 0,
		takecount: 1000,
		eventId,
		status: 'Participant',
	});

	const isLoading = eventLoading || userLoading;

	const allCompanyUsers = data?.body ?? [];

	const allCompanyUsersList: UserListItem[] = allCompanyUsers.map(item => ({
		id: item.user.id,
		name: createFullName({ firstName: item.user.firstName, lastName: item.user.lastName, middleName: item.user.middleName ?? undefined }),
		position: item.positionUser?.name ?? '',
		department: item.departmentUser?.department.name,
		avatar: item.user.avatar,
	}));

	const eventInfo = eventData?.body;

	useEffect(() => {
		refetch();
		dispatch(setSelectedUsersInfo([]));
	}, [eventId, refetch]);

	useEffect(() => {
		if (eventUsersData) {
			const selectedUsers = allCompanyUsersList
				.filter(user => eventUsersData.body?.find(({ userInfo }) => user.id === userInfo.userId))
				.map(user => ({
					...user,
					eventUserId: eventUsersData?.body?.find(({ userInfo }) => user.id === userInfo.userId)?.id,
					avatarId: eventUsersData?.body?.find(({ userInfo }) => user.id === userInfo.userId)?.userInfo.imageId,
				}));
			dispatch(setSelectedUsersInfo(selectedUsers));
		}
	}, [data, eventUsersData]);

	// * Render
	return (
		<PageDecorator>
			<PageTitle title="Календарь событий" />

			<ConditionalRendering
				initialLoading={isLoading}
				isSuccessful={!!eventInfo}
				LoadedResult={
					eventInfo && (
						<>
							<EventInfoCard eventInfo={eventInfo} />
							<EventComments
								eventId={eventId}
								comments={eventInfo.eventComments || []}
							/>
						</>
					)
				}
			/>
		</PageDecorator>
	);
};
