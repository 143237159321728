import React, { useEffect } from 'react';
import { FileInfo, ImageInfo, initialFilesImages } from 'src/app/redux/queries/event-service/types/types';
import { actionsEventCalendar } from 'src/app/redux/state/event-calendar/slice';
import { useAppDispatch, useAppSelector } from 'src/app/redux/utils';
import { ReactComponent as DeleteSVG } from 'src/shared/assets/svg/action/delete.svg';
import { galleyFileToImageContent } from 'src/shared/lib/file';
import { formatBytes } from 'src/shared/lib/string';
import { DropZone } from 'src/shared/ui/DropZone/DropZone';
import { IconButton } from 'src/shared/ui/_buttons/IconButton';
import { GalleyFile } from 'src/shared/ui/_galleries/Gallery/_types';
import { createGalleyFileFromImageInfo } from 'src/shared/ui/_galleries/Gallery/_utils';
import { v4 } from 'uuid';
import { getIcon } from '../../../EventCalendarPage/lib/getIcon/getIcon';
import s from './AddFilesToEvent.module.scss';

interface Props {
	formName: string;
	onSubmitAfterValidation: () => void;
	setFiles: React.Dispatch<React.SetStateAction<File[]>>;
	files: File[];
	initialData: initialFilesImages;
}

export const AddFilesToEvent: React.FC<Props> = props => {
	const {
		formName, //
		onSubmitAfterValidation,
		setFiles,
		files,
		initialData,
	} = props;

	const { images: defaultImages, files: defaultFiles } = initialData;

	// * Selectors
	const eventImages = useAppSelector(state => state.event_calendar.event_form.eventImages);

	// * Actions
	const dispatch = useAppDispatch();
	const { setEventImages } = actionsEventCalendar;

	// - Initial files and images
	useEffect(() => {
		const existingFiles = defaultFiles?.map((file: FileInfo) => {
			const newFile = new File([''], file.name, { type: file.extension });
			// Object.defineProperty(newFile, 'size', { value: file.size });
			return newFile;
		});
		setFiles(existingFiles || []);
	}, [defaultFiles]);

	useEffect(() => {
		dispatch(setEventImages(defaultImages ? [...defaultImages] : []));
	}, [defaultImages]);

	// * IMAGES
	const onDropImage = async (acceptedFiles: any) => {
		const imgs = acceptedFiles.map((img: any) => {
			return Object.assign(img, {
				id: `new_${v4()}`,
				preview: URL.createObjectURL(img),
			});
		});

		const imgFormat = Promise.all(
			imgs.map((img: GalleyFile) => {
				return galleyFileToImageContent(img);
			}),
		);

		const imgFormatData = (await imgFormat).map(item => ({
			id: `new_${v4()}`,
			parentId: null,
			...item,
		}));

		dispatch(setEventImages([...eventImages, ...imgFormatData]));
	};

	const deleteImage = (id: string) => {
		const filtered = eventImages?.filter(img => img.id !== id);
		dispatch(setEventImages(filtered));
	};

	// * FILES
	const onDropFiles = async (acceptedFiles: File[]) => {
		setFiles(prevState => [...prevState, ...acceptedFiles]);
	};

	const deleteFile = (id: number) => {
		setFiles(prevState => prevState.filter(file => file.name !== prevState[id].name));
	};

	const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		onSubmitAfterValidation();
	};

	const renderImages = (images: ImageInfo[]) =>
		images.map(image => {
			const img = createGalleyFileFromImageInfo(image);
			return (
				<div
					className={s.image_container}
					key={img.id}
				>
					<img
						className={s.image}
						src={img.preview}
						alt={img.name}
						onClick={() => deleteImage(img.id)}
					/>
					<IconButton
						Icon={<DeleteSVG />}
						className={s.delete}
						onClick={() => deleteImage(img.id)}
					/>
				</div>
			);
		});

	const renderFiles = (files: any) =>
		files.map((file: any, id: number) => (
			<div
				className={s.file}
				key={file.name}
			>
				<div className={s.formatIcon}>{getIcon(file.extension)}</div>
				<div className={s.name}>{file.name}</div>
				<div className={s.size}>{formatBytes(file.size)}</div>
				<div onClick={() => deleteFile(id)}>
					<DeleteSVG />
				</div>
			</div>
		));

	// * Render
	return (
		<div>
			<form
				id={formName}
				onSubmit={onSubmit}
			/>
			<div className={s.gallery}>
				<h2 className={s.title}>Галерея</h2>
				<DropZone
					accept={{ 'image/*': ['.png', '.tiff', '.jpg', '.mpeg', '.avi', '.wmv', '.mp4', '.webm', '.gif'] }}
					onDrop={onDropImage}
					maxFiles={10}
				/>
				{eventImages.length > 0 && <div className={s.gallery_row}>{renderImages(eventImages)}</div>}
			</div>

			<div className={s.gallery}>
				<h2 className={s.title}>Документы</h2>
				<DropZone
					onDrop={onDropFiles}
					maxFiles={10}
				/>
				{files.length > 0 && <div className={s.gallery_row}>{renderFiles(files)}</div>}
			</div>
		</div>
	);
};
