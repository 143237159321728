import cn from 'classnames';
import s from './StatisticsTable.module.scss';
import { ReactNode } from 'react';
import { COLUMNS_SENDERS } from './consts';
import { flexRender, useReactTable, getCoreRowModel } from '@tanstack/react-table';

export interface TABLE_DATA {
	id: string;
	fullname: ReactNode;
	done: number | string;
	onTime: string | number;
	notOnTime: string | number;
	rejected: number | string;
	inWork: number | string;
}

export const StatisticsTable = () => {
	const table = useReactTable({
		data: [],
		columns: COLUMNS_SENDERS,
		getCoreRowModel: getCoreRowModel(),
	});

	return (
		<div>
			<div className={s.table}>
				{table.getHeaderGroups().map(item => (
					<div
						key={item.id}
						className={s.table_column}
					>
						{item.headers.map(item => (
							<div key={item.id}>{item.isPlaceholder ? null : flexRender(item.column.columnDef.header, item.getContext())}</div>
						))}
					</div>
				))}
				{/* {data  && table.getRowModel().rows.map(row => (
					<div
						key={row.id}
						className={cn(s.table_row, s.cell_card)}
					>
						{row.getVisibleCells().map(cell => (
							<div key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
						))}
					</div>
				))} */}
			</div>
		</div>
	);
};
