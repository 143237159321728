import { useState } from 'react';
import s from './AllClaims.module.scss';
import { OPTION_PRIORITY, STATUS } from 'src/pages/claims/consts/consts';
import { backendISOUTCToLocalLuxon } from 'src/shared/lib/date';
import { ConditionalRendering } from 'src/shared/providers';
import { EmptyData } from 'src/shared/ui/EmptyData/EmptyData';
import { ClaimContextProvider, FilterClaim } from '../../context/context';
import { useClaimsFilterQueries } from '../../hooks/useClaimsFilterQueries';
import { Filter } from '../Filter/Filter';
import { Table } from '../Table/Table';
import { RemoveClaim } from '../RemoveClaim/RemoveClaim';
import { routerPath } from 'src/app/router/paths';
import { useNavigate } from 'react-router-dom';

const CURRENT_FILTER = {
	topic: '',
	status: { id: '', name: '' },
	priority: { id: '', name: '' },
	sender: { id: '', name: '' },
};

export const AllClaims = ({ userId }: { userId?: string }) => {
	const [filters, setFilters] = useState<FilterClaim>(CURRENT_FILTER);
	const [isRemoveFlag, setIsRemoveFlag] = useState(false);
	const navigate = useNavigate();
	// * Hook
	const { isLoaded, claims, isLoading } = useClaimsFilterQueries({ userId, filter: filters, removeFlag: isRemoveFlag });

	const claimsPresented = claims.map(claims => {
		const { userName, createdAtUtc, name, status, priority, id } = claims;

		return {
			id: id,
			sender: (
				<div>
					<p>{userName}</p>
					<p className={s.data}>{backendISOUTCToLocalLuxon(createdAtUtc).toFormat('dd.LL.yyyy')}</p>
				</div>
			),
			name: name,
			status: STATUS.find(item => item.id === status)?.name,
			priority: OPTION_PRIORITY.find(item => item.id === priority)?.name,
			department: '-',
			executor: '-',
			dropdown: (
				<RemoveClaim
					id={id}
					isRemoveFlag={isRemoveFlag}
					setIsRemoveFlag={setIsRemoveFlag}
				/>
			),
		};
	});

	const onClaimClick = (claimId: string) => {
		const claimUrl = `${routerPath.claim.page}/${claimId}`;
		navigate(claimUrl);
	};

	// * Render
	return (
		<>
			<ClaimContextProvider
				claims={claimsPresented}
				filter={filters}
				updatedFilter={setFilters}
			>
				<Filter userId={userId} />
				<ConditionalRendering
					initialLoading={isLoading}
					isSuccessful={claims.length > 0}
					LoadedResult={<Table onRowClick={rowId => onClaimClick(claims[rowId].id)} />}
					FailedResult={isLoaded && <EmptyData />}
				/>
			</ClaimContextProvider>
		</>
	);
};
