import { FC, forwardRef, useRef } from 'react';
import Scrollbar from 'react-scrollbars-custom';
import { CELL_GAP, CELL_SIZE } from '../../consts';

const barWidth = 0.5; // in REM
const positionGap = 0.6; // in REM

interface Props {
	children: React.ReactNode;
	usersTableWidth: number; // In REM
	usersCount: number;
}

export const ScrollbarWrapper = forwardRef<any, Props>((props, ref) => {
	const {
		children, //
		usersTableWidth,
		usersCount,
	} = props;

	// * Render
	return (
		<>
			<Scrollbar
				ref={ref}
				style={{ width: '100%', height: '30rem' }}
				trackXProps={{
					renderer: props => {
						const { elementRef, ...restProps } = props;
						return (
							<span
								{...restProps}
								ref={elementRef}
								style={{
									...restProps.style,
									// width: 'calc(100% - 43.7rem)',
									width: `calc(100% - ${usersTableWidth + barWidth}rem)`,
									right: `${positionGap}rem`,
									left: 'none',
									backgroundColor: 'var(--color-bg-hover-75)',
									height: `${barWidth}rem`,
								}}
							/>
						);
					},
				}}
				trackYProps={{
					renderer: props => {
						const { elementRef, ...restProps } = props;
						return (
							<span
								{...restProps}
								ref={elementRef}
								style={{
									...restProps.style,
									height: `calc(100% - ${CELL_SIZE * 2 + CELL_GAP + barWidth}rem)`,
									bottom: `${positionGap}rem`,
									top: 'none',
									backgroundColor: 'var(--color-bg-hover-75)',
									width: `${barWidth}rem`,
								}}
							/>
						);
					},
				}}
				thumbXProps={{
					renderer: props => {
						const { elementRef, ...restProps } = props;
						return (
							<div
								{...restProps}
								ref={elementRef}
								style={{ ...restProps.style, backgroundColor: 'var(--color-secondary-1)' }}
							/>
						);
					},
				}}
				thumbYProps={{
					renderer: props => {
						const { elementRef, ...restProps } = props;
						return (
							<div
								{...restProps}
								ref={elementRef}
								style={{ ...restProps.style, backgroundColor: 'var(--color-secondary-1)' }}
							/>
						);
					},
				}}
				contentProps={{
					renderer: props => {
						const { elementRef, ...restProps } = props;
						return (
							<div
								{...restProps}
								style={{ ...restProps.style, display: 'inline-block' }} // ! inline-block нужен чтобы position: sticky таблицы с пользователями работал корректно!
								ref={elementRef}
								className="Content"
							/>
						);
					},
				}}
			>
				{children}
			</Scrollbar>
		</>
	);
});
