import { createColumnHelper } from '@tanstack/react-table';
import { TABLE_DATA } from './Table';

const columnsHelper = createColumnHelper<TABLE_DATA>();

export const COLUMNS_SENDERS = [
	columnsHelper.accessor('sender', {
		header: 'ОТПРАВИТЕЛЬ',
		cell: info => info.getValue(),
	}),
	columnsHelper.accessor('name', {
		header: 'ТЕМА',
		cell: info => info.getValue(),
	}),
	columnsHelper.accessor('status', {
		header: 'СТАТУС',
		cell: info => info.getValue(),
	}),
	columnsHelper.accessor('priority', {
		header: 'ПРИОРИТЕТНОСТЬ',
		cell: info => info.getValue(),
	}),
	columnsHelper.accessor('department', {
		header: 'ДЕПАРТАМЕНТ',
		cell: info => info.getValue(),
	}),
	columnsHelper.accessor('executor', {
		header: 'ИСПОЛНИТЕЛЬ',
		cell: info => info.getValue(),
	}),
	columnsHelper.accessor('dropdown', {
		header: '',
		cell: info => info.getValue(),
	}),
];
