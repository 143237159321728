import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { GroupResponse } from 'src/app/redux/queries/vacation-service/types/resTypes';
import { usePatchGroupsByGroupIdMutation, usePostGroupsMutation } from 'src/app/redux/queries/vacation-service/vacation_serviceAPI';
import { ButtonPair } from 'src/entities/_buttons/ButtonPair';
import { CheckboxField } from 'src/shared/ui/_fields/CheckboxField';
import { StringField } from 'src/shared/ui/_fields/StringField';
import { Heading } from 'src/shared/ui/Heading';
import * as yup from 'yup';
import { PATHS, PathValues } from '../../../consts/consts';
import s from './AddEditGroupMC.module.scss';

interface formValues {
	name: string;
	shortName: string;
	isEmailSend: boolean;
}

interface Props {
	closeModal: () => void;
	group?: GroupResponse;
}

export const AddEditGroupMC: React.FC<Props> = props => {
	const {
		closeModal, //
		group,
	} = props;

	const isEdit = !!group;

	const defaultValues = {
		name: group?.name || '',
		shortName: group?.shortName || '',
		isEmailSend: false, // не приходит с бэка
	};

	// * Queries
	const [createGroup, { isLoading: createLoading }] = usePostGroupsMutation();
	const [editGroup, { isLoading: editLoading }] = usePatchGroupsByGroupIdMutation();

	const isLoading = createLoading || editLoading;

	// * Form
	const schema = yup.object().shape({
		name: yup.string().required('Введите название группы'), // TODO min max
		shortName: yup.string().required('Введите короткое группы'), // TODO min max
	});

	const formMethods = useForm({
		defaultValues,
		resolver: yupResolver(schema),
	});

	const { handleSubmit, formState } = formMethods;

	const hasErrors = Object.keys(formState.errors).length > 0;

	const createBodyEdit = (data: formValues) => {
		const body: {
			op: 'replace';
			path: PathValues;
			value: string | boolean;
		}[] = [];

		for (const key in PATHS) {
			if (data[key as keyof formValues] !== defaultValues[key as keyof formValues]) {
				body.push({
					op: 'replace',
					path: PATHS[key as keyof typeof PATHS],
					value: data[key as keyof formValues],
				});
			}
		}

		return body;
	};

	const onCreate = (data: typeof defaultValues) => {
		createGroup({
			groupBody: {
				name: data.name,
				shortName: data.shortName,
			},
		})
			.unwrap()
			.then(() => closeModal())
			.catch(e => console.log(e)); // TODO data.isEmailSend
	};

	const onEdit = (data: formValues) => {
		if (!group) return;

		// - только измененные поля
		const body = createBodyEdit(data);

		if (body.length > 0) {
			editGroup({
				groupId: group.id,
				body,
			})
				.unwrap()
				.then(() => closeModal())
				.catch(e => console.log(e));
		} else closeModal();
	};

	const onSubmit = (data: typeof defaultValues) => (isEdit ? onEdit(data) : onCreate(data));

	// * Render
	return (
		<div className={s.container}>
			<Heading level={2}>{isEdit ? 'Редактирование группы' : 'Новая группа'}</Heading>

			<FormProvider {...formMethods}>
				<form
					onSubmit={handleSubmit(onSubmit)}
					className={s.inner}
				>
					<StringField
						name="name"
						label="Полное название группы"
						placeholder="Введите полное название"
					/>

					<StringField
						name="shortName"
						label="Сокращённое название"
						placeholder="Введите сокращённое название"
					/>

					<CheckboxField
						name="isEmailSend"
						label="Высылать e-mail о пересечениях отпусков"
					/>

					<ButtonPair
						primaryText={isEdit ? 'Сохранить' : 'Создать'}
						primaryIsLoading={isLoading}
						primaryDisabled={hasErrors}
						secondaryText="Отмена"
						secondaryOnClick={closeModal}
						secondaryIsLoading={isLoading}
					/>
				</form>
			</FormProvider>
		</div>
	);
};
