import React, { useState } from 'react';
import { CategoryInfo } from 'src/app/redux/queries/survey-service/types';
import { ReactComponent as AddSVG } from 'src/shared/assets/svg/action/add.svg';
import { Button } from 'src/shared/ui/_buttons/Button';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { FormValues, NewCategoryMC } from '../../../../pages/surveys/pages/SurveyPage/Content/TemplateContent/NewCategoryMC/NewCategoryMC';
import { Category } from '../Category/Category';
import { NoCategory } from '../Category/NoCategory';
import s from './Categories.module.scss';

interface Props {
	categories: CategoryInfo[];

	getCategoryId: (id: string) => void;
	getCategoryInfo: (value: FormValues) => void;
	getNewCategoryInfo: (value: FormValues) => void;

	isLoading: boolean;
	setCategoriesIds?: React.Dispatch<React.SetStateAction<string[]>>;
}

export const Categories: React.FC<Props> = props => {
	const {
		categories,

		getCategoryId, // Возвращает id для удаления категории.
		getCategoryInfo, // Возвращает данные о выбранной для редактирования категории.
		getNewCategoryInfo, // Возвращает данные при создании новой категории.

		isLoading,
		setCategoriesIds,
	} = props;

	// * User Actions
	const [newCategoryModal, setNewCategoryModal] = useState(false);
	const toggleModal = () => setNewCategoryModal(prevState => !prevState);

	const onCreate = (values: FormValues) => {
		getNewCategoryInfo(values);
		toggleModal();
	};

	// * Render
	return (
		<>
			{newCategoryModal && (
				<ModalNewProto
					isOpen={newCategoryModal}
					onClose={toggleModal}
					width="s"
				>
					<NewCategoryMC
						toggleModal={toggleModal}
						title="Новая категория"
						saveButtonTitle="Создать"
						onSave={onCreate}
						categories={categories}
						isLoading={isLoading}
					/>
				</ModalNewProto>
			)}

			<div className={s.container}>
				<h3>Категории</h3>

				<div className={s.list}>
					{categories.map(category => (
						<Category
							key={category.id}
							categories={categories}
							category={category}
							getCategoryInfo={getCategoryInfo}
							getCategoryId={getCategoryId}
							isLoading={isLoading}
							setCategoriesIds={setCategoriesIds}
						/>
					))}

					<NoCategory />
				</div>

				<Button
					LeftIcon={<AddSVG />}
					variant="secondary"
					onClick={toggleModal}
					isLoading={isLoading}
					wide
				>
					Добавить категорию
				</Button>
			</div>
		</>
	);
};
