import { createColumnHelper } from '@tanstack/react-table';
import { TABLE_DATA } from './StatisticsTable';

const columnsHelper = createColumnHelper<TABLE_DATA>();

export const COLUMNS_SENDERS = [
	columnsHelper.accessor('fullname', {
		header: 'ФИО',
		cell: info => info.getValue(),
	}),
	columnsHelper.accessor('done', {
		header: 'ВЫПОЛНЕНО',
		cell: info => info.getValue(),
	}),
	columnsHelper.accessor('onTime', {
		header: 'В СРОК',
		cell: info => info.getValue(),
	}),
	columnsHelper.accessor('notOnTime', {
		header: 'НЕ В СРОК',
		cell: info => info.getValue(),
	}),
	columnsHelper.accessor('rejected', {
		header: 'ОТКЛОНЕНО',
		cell: info => info.getValue(),
	}),
	columnsHelper.accessor('inWork', {
		header: 'В РАБОТЕ',
		cell: info => info.getValue(),
	}),
];
