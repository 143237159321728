export const getYears = (tab: string) => {
	const years = [];

	const currentYear = new Date().getFullYear();
	let startYear = 2024;
	const endYear = currentYear;

	if (tab === 'events') startYear = currentYear;

	for (let year = startYear; year <= endYear; year++) {
		years.push({ name: `${year} год`, id: year });
	}

	if (tab !== 'past') years.push({ name: `${currentYear + 1} год`, id: currentYear + 1 });
	return years;
};
