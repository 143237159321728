import { MAX_STEPS } from '../../../../consts/consts';

export const getSubmitButtonText = (currentStep: number, eventId: string | undefined) => {
	if (currentStep === MAX_STEPS) {
		if (eventId) {
			return 'Редактировать';
		}
		return 'Создать';
	}
	return 'Далее';
};
