import cn from 'classnames';
import { useState } from 'react';
import s from './ClaimsRoute.module.scss';
import { ReactComponent as EditSVG } from '../../../../../../shared/assets/svg/action/edit.svg';
import { Heading } from 'src/shared/ui/Heading';
import { RouteTemplate } from '../../../ClaimSystemPage/ui/RoutesAndCategories/ui/RouteTemplate/RouteTemplate';

export const ClaimsRoute = () => {
	// * Render
	return (
		<div className={s.wrapper}>
			<div className={s.title}>
				<Heading
					level={2}
					size={3}
				>
					Маршрут заявки
				</Heading>
				{/* <EditSVG fill="#818FB4" /> */}
			</div>
			<RouteTemplate />
			{/* <div>
				<p>Иванов Иван Иванович</p>
				<p className={s.job_title}>Главный бухгалтер</p>
				<div className={s.approvers}>
					<div className={s.dot} />
					<p>Не одобрено</p>
				</div>
			</div> */}
		</div>
	);
};
