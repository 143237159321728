import s from './ClaimInfo.module.scss';
import { Heading } from 'src/shared/ui/Heading';
import { preparedData } from 'src/pages/claims/lib/preparedData';
import { OPTION_PRIORITY, STATUS } from 'src/pages/claims/consts/consts';
import { ClaimResponse } from 'src/app/redux/queries/claim-service/types/resTypes';
import { ClaimInfoAdmin } from '../ClaimInfoAdmin/ClaimInfoAdmin';

export interface Props {
	userName: string;
	claimData: ClaimResponse;
	isAdmin: boolean;
}

export const ClaimInfo = (props: Props) => {
	const {
		userName, //
		claimData,
		isAdmin,
	} = props;

	// * Render
	return (
		<div className={s.claim}>
			<Heading
				level={2}
				size={3}
			>
				{claimData.name || ''}
			</Heading>
			<Heading level={4}>{userName}</Heading>
			<div className={s.claim_info}>
				<div>
					<p className={s.claim_title}>Дата создания</p>
					<p>{preparedData(claimData.createdAtUtc)}</p>
				</div>
				<div>
					<p className={s.claim_title}>Ожидаемая дата завершения</p>
					<p>{preparedData(claimData.deadLine)}</p>
				</div>
				<div>
					<p className={s.claim_title}>Дата завершения</p>
					<p>Дата</p>
				</div>
			</div>
			<div>
				<p className={s.claim_title}>Руководитель</p>
				<p>-</p>
			</div>
			{isAdmin ? (
				<ClaimInfoAdmin claimData={claimData} />
			) : (
				<>
					<div className={s.claim_info}>
						<div>
							<p className={s.claim_title}>Департамент</p>
							<p>-</p>
						</div>
						<div>
							<p className={s.claim_title}>Категория запроса</p>
							<p>{claimData.category?.name || '-'}</p>
						</div>
						<div>
							<p className={s.claim_title}>Приоритетность</p>
							{claimData.priority && <p>{OPTION_PRIORITY.find(item => item.id === claimData.priority)?.name}</p>}
						</div>
					</div>
					<div className={s.claim_info}>
						<div>
							<p className={s.claim_title}>Ответственный</p>
							<p>-</p>
						</div>
						<div>
							<p className={s.claim_title}>Статус</p>
							{claimData.status && <p>{STATUS.find(item => item.id === claimData.status)?.name}</p>}
						</div>
						<div>
							<p className={s.claim_title}>Дата завершения</p>
							<p>{preparedData(claimData.deadLine)}</p>
						</div>
					</div>
				</>
			)}
			<div>
				<p className={s.claim_title}>Описание</p>
				<p>{claimData.content}</p>
			</div>
			<div>
				<p className={s.claim_title}>Приложения</p>
				<p>Тут будут файлы</p>
			</div>
		</div>
	);
};
