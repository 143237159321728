import { DateTime } from 'luxon';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { claim_serviceAPI } from 'src/app/redux/queries/claim-service/claim_serviceAPI';
import { ClaimResponse } from 'src/app/redux/queries/claim-service/types/resTypes';
import { useGetDepartmentFindQuery } from 'src/app/redux/queries/department-service/department_serviceAPI';
import { user_serviceAPI } from 'src/app/redux/queries/user-service/user_serviceAPI';
import { EDIT_CLAIM, OPTION_PRIORITY, STATUS } from 'src/pages/claims/consts/consts';
import { dateToBackendISO } from 'src/shared/lib/date';
import { SelectSingleField } from 'src/shared/ui/_fields/SelectSingleField';
import { DatePickerInput } from 'src/shared/ui/_inputs/date_pickers/DatePickerInput';
import { SelectSingle } from 'src/shared/ui/_inputs/selects/SelectSingle';
import { type EditClaimsKeys, type Option } from '../../../../types';
import { ClaimId } from '../ClaimPage/ClaimPage';
import s from './ClaimInfoAdmin.module.scss';

interface FormData {
	department: Option;
	category: Option;
	manager: Option;
	priority: Option;
	status: Option;
	deadline: string | null;
}

const CURRENT_DATE = new Date();
const EMPTY_FIELD: Option = { id: '', name: '' };

export const ClaimInfoAdmin = ({ claimData }: { claimData: ClaimResponse }) => {
	const { claimId } = useParams() as unknown as ClaimId;
	const formMethods = useForm<FormData>({
		defaultValues: {
			department: EMPTY_FIELD,
			category: claimData.category ?? EMPTY_FIELD,
			manager: EMPTY_FIELD,
			priority: OPTION_PRIORITY.find(item => item.id === claimData.priority) ?? EMPTY_FIELD,
			status: STATUS.find(item => item.id === claimData.status) ?? EMPTY_FIELD,
			deadline: null,
		},
	});

	const { setValue, control, watch } = formMethods;

	// * Api
	const [updateClaim] = claim_serviceAPI.usePatchClaimsByClaimIdMutation();
	const { data: departmentData } = useGetDepartmentFindQuery({
		skipcount: 0,
		takecount: 1000,
	});
	const { currentData: categoriesTreeData } = claim_serviceAPI.useGetCategoriesQuery({
		skipcount: 0,
		takecount: 1000,
	});
	const { data: usersTreeData } = user_serviceAPI.useGetUserFindQuery({
		skipcount: 0,
		takecount: 1000,
		includedepartments: true,
		isactive: true,
	});

	// * Department
	const departmentAll = (departmentData?.body || []).map(department => ({
		id: department.id,
		name: department.shortName,
		fullName: department.name,
	}));

	// * Categories
	const categories =
		categoriesTreeData?.body?.map(item => ({
			id: item.id,
			name: item.name,
		})) || [];

	// * Managers
	const managers =
		usersTreeData?.body
			?.filter(item => {
				return item.departmentUser?.role === 'Manager';
			})
			.map(item => {
				const { firstName, lastName, middleName } = item.user;
				return {
					id: item.user.id,
					name: `${firstName} ${middleName || ''} ${lastName}`,
				};
			}) || [];

	// * Update claim
	const updateFields = (element: EditClaimsKeys, value: any) => {
		updateClaim({
			claimId: claimId,
			body: [
				{
					op: 'replace',
					value: value,
					path: EDIT_CLAIM[element],
				},
			],
		})
			.unwrap()
			.catch(error => console.log(error));
	};

	const onChange = (element: EditClaimsKeys, value: Option | Date | null) => {
		switch (element) {
			case 'category': {
				const selectedOption = value as Option;
				setValue('category', selectedOption);
				updateFields(element, selectedOption.id);
				break;
			}
			case 'priority': {
				const selectedOption = value as Option;
				setValue('priority', selectedOption);
				updateFields(element, selectedOption.id);
				break;
			}
			case 'deadline': {
				const selectedOption = value as Date | null;
				const deadline = selectedOption ? (dateToBackendISO(selectedOption) as string) : null;
				setValue('deadline', deadline);
				const newDeadline = watch('deadline');
				updateFields(element, newDeadline);
				break;
			}
			case 'status': {
				const selectedOption = value as Option;
				setValue('status', selectedOption);
				updateFields(element, selectedOption.id);
				break;
			}
			default:
				return 'Unknown element';
		}
	};

	// * Render
	return (
		<>
			<FormProvider {...formMethods}>
				<form>
					<div className={s.claim_info}>
						<SelectSingleField
							name="department"
							label="Департамент"
							placeholder="Не выбран"
							options={departmentAll}
							keyNames={{
								name: 'name',
								value: 'id',
								tooltipText: 'fullName',
							}}
							disabled
						/>
						<Controller
							name="category"
							control={control}
							render={({ field }) => (
								<SelectSingle
									{...field}
									label="Категория запроса"
									placeholder="Не выбрана"
									keyNames={{
										name: 'name',
										value: 'id',
									}}
									selectedOption={{
										name: field.value.name,
										id: field.value.id,
									}}
									setSelectedOption={item => onChange('category', item)}
									options={categories}
								/>
							)}
						/>
						<Controller
							name="priority"
							control={control}
							render={({ field }) => (
								<SelectSingle
									{...field}
									label="Приоритетность"
									keyNames={{
										name: 'name',
										value: 'id',
									}}
									selectedOption={{
										name: field.value.name,
										id: field.value.id,
									}}
									setSelectedOption={item => onChange('priority', item)}
									options={OPTION_PRIORITY}
								/>
							)}
						/>
					</div>
					<div className={s.claim_info}>
						<SelectSingleField
							name="manager"
							label="Ответственный"
							placeholder="Не выбран"
							options={managers}
							disabled
						/>
						<Controller
							name="status"
							control={control}
							render={({ field }) => (
								<SelectSingle
									{...field}
									label="Статус"
									placeholder="Не выбрана"
									keyNames={{
										name: 'name',
										value: 'id',
									}}
									selectedOption={{
										name: field.value.name,
										id: field.value.id,
									}}
									setSelectedOption={item => onChange('status', item)}
									options={STATUS}
								/>
							)}
						/>
						{/* <SelectSingleField
							name="status"
							label="Статус"
							options={STATUS}
							disabled
						/> */}
						<Controller
							name="deadline"
							control={control}
							render={() => (
								<DatePickerInput
									label="Дата окончания"
									placeholderText="ДД.ММ.ГГ"
									minDate={CURRENT_DATE}
									selectedDate={claimData.deadLine ? DateTime.fromISO(claimData.deadLine).toJSDate() : null}
									setSelectedDate={item => onChange('deadline', item)}
								/>
							)}
						/>
					</div>
				</form>
			</FormProvider>
		</>
	);
};
