import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { position_serviceAPI } from 'src/app/redux/queries/position-service/position_serviceAPI';
import { useGetUserFindQuery } from 'src/app/redux/queries/user-service/user_serviceAPI';
import {
	useGetGroupsByGroupIdSubgroupsAndSubgroupIdQuery,
	usePatchGroupsByGroupIdSubgroupsAndSubgroupIdMutation,
} from 'src/app/redux/queries/vacation-service/vacation_serviceAPI';
import { SelectStringSearchFilter, SelectValue } from 'src/entities/_filters/SelectStringSearchFilter';
import { SelectedCount } from 'src/entities/_typography/SelectedCount';
import { createFullName } from 'src/shared/lib/string';
import { AddButton } from 'src/shared/ui/_buttons/AddButton';
import { Heading } from 'src/shared/ui/Heading';
import { SUB_GROUP } from '../../consts/consts';
import { SubGroupType } from '../../types';
import { AddUserContainer } from '../AddUserContainer/AddUserContainer';
import s from './SubGroupUsers.module.scss';

interface Props {
	type?: SubGroupType;
	name: string;
	id: string;
}

export const SubGroupUsers: FC<Props> = props => {
	const {
		type, //
		name,
		id,
	} = props;

	const { groupId } = useParams() as { groupId: string };

	// * Modal
	const [showModal, setShowModal] = useState(false);
	const toggleModal = () => setShowModal(prevState => !prevState);

	// * API
	const [editUsers, { isLoading: editLoading }] = usePatchGroupsByGroupIdSubgroupsAndSubgroupIdMutation();

	const { data, isLoading: isLoadingGetUsers } = useGetUserFindQuery({
		skipcount: 0,
		takecount: 1000,
		includedepartments: true,
		isactive: true,
		includecurrentavatar: true,
	});

	const { data: subGroupData, isLoading: isLoadingSubGroup } = useGetGroupsByGroupIdSubgroupsAndSubgroupIdQuery({
		groupId: groupId,
		subgroupId: id,
	});

	const { currentData: positionsData } = position_serviceAPI.useFindPositionsQuery({
		skipcount: 0,
		takecount: 1000,
		includeDeactivated: false,
		isascendingsort: true,
	});

	const allPositions = positionsData?.body || [];
	const userIds = subGroupData?.body?.users?.map(user => user.id) || [];

	const allUsers =
		data?.body?.map(item => ({
			id: item.user.id,
			name: createFullName({ firstName: item.user.firstName, lastName: item.user.lastName, middleName: item.user.middleName ?? undefined }),
			avatar: item.user.avatar,
			department: item.departmentUser?.department.name,
			position: '',
		})) || [];

	const onEdit = (userIds: string[]) => {
		editUsers({
			groupId,
			subgroupId: id,
			body: [
				{
					op: 'replace',
					value: userIds,
					path: '/UserIds',
				},
			],
		})
			.unwrap()
			.then(() => toggleModal())
			.catch(e => console.log(e));
	};

	// * Filters
	const [position, setPosition] = useState<SelectValue>({ id: '', name: '' });
	const [searchValue, setSearchValue] = useState('');

	// * Render
	return (
		<>
			{showModal && (
				<AddUserContainer
					showModal={showModal}
					toggleModal={toggleModal}
					editAction={onEdit}
					editLoading={editLoading || isLoadingSubGroup || isLoadingGetUsers}
					allUsers={allUsers.filter(user => !userIds.find(existingUserId => existingUserId === user.id))}
				/>
			)}

			<div className={s.container}>
				<Heading level={2}>
					{type && SUB_GROUP[type]} <span className={s.subgroup_name}>{`“${name}”`}</span>
				</Heading>

				<SelectStringSearchFilter
					selectPlaceholder="Должность"
					selectValue={position}
					setSelect={(value: SelectValue) => setPosition(value)}
					selectOptions={allPositions}
					searchPlaceholder="Введите ФИО сотрудника"
					searchStringValue={searchValue}
					setSearchString={setSearchValue}
				/>

				<div className={s.inner}>
					<SelectedCount
						title="Выбрано сотрудников"
						count={0}
					/>

					<AddButton
						title="Добавить сотрудников"
						onClick={toggleModal}
					/>
				</div>
			</div>
		</>
	);
};
