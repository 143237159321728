import s from './ClaimsComments.module.scss';
import { Heading } from 'src/shared/ui/Heading';
import { UserAvatar } from 'src/app/redux/state/user/user/types';
import { Avatar } from 'src/shared/ui/Avatar/Avatar';
import { ReactComponent as SendSVG } from 'src/shared/assets/svg_icons/action/send.svg';
import { ReactComponent as AttachmentSVG } from 'src/shared/assets/svg_icons/file/attachment.svg';
import { IconButton } from 'src/shared/ui/_buttons/IconButton';
import { TextInput } from 'src/shared/ui/_inputs/text_Inputs/TextInput';
import { claim_serviceAPI } from 'src/app/redux/queries/claim-service/claim_serviceAPI';
import { AllComments } from '../AllComments/AllComments';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/redux/store';

interface CommentsData {
	message: string;
	files?: File[] | [];
}

export const ClaimsComments = ({ claimId }: { claimId: string }) => {
	const [comment, setComment] = useState<CommentsData>({
		message: '',
		files: [],
	});

	// * Api
	const [createComment] = claim_serviceAPI.usePostClaimsByClaimIdCommentsMutation();
	const personInfo = useSelector((state: RootState) => state.user_service.user.userInfo)?.user;

	// * Create comment
	const addComment = () => {
		createComment({
			claimId: claimId,
			createCommentRequest: {
				content: comment.message,
			},
		})
			.unwrap()
			.then(() => {
				setComment({
					message: '',
					files: [],
				});
			})
			.catch(error => {
				console.log(error);
			});
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setComment(prev => ({
			...prev,
			message: e.target.value,
		}));
	};

	// * Render
	return (
		<div className={s.wrapper}>
			<Heading
				level={2}
				size={3}
				className={s.title}
			>
				Комментарии
			</Heading>
			<div className={s.comments}>
				<Avatar
					size="large"
					name={`${personInfo?.lastName} ${personInfo?.firstName}`}
					userAvatar={personInfo?.avatar}
				/>
				<TextInput
					placeholder="Введите текст комментария"
					value={comment.message}
					onChange={handleInputChange}
					RightIcon={<AttachmentSVG />}
					onRightIconClick={() => console.log('fhf')}
					required
				/>
				<IconButton
					Icon={<SendSVG />}
					onClick={addComment}
				/>
			</div>
			<AllComments claimId={claimId} />
		</div>
	);
};
