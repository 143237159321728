export const ServiceName = {
	AUTH_SERVICE: 'authservice' as const,
	USER_SERVICE: 'userservice' as const,
	WIKI_SERVICE: 'wikiservice' as const,
	IMAGE_SERVICE: 'imageservice' as const,
	FILE_SERVICE: 'fileservice' as const,
	TIME_SERVICE: 'timeservice' as const,
	RIGHTS_SERVICE: 'rightsservice' as const,
	OFFICE_SERVICE: 'officeservice' as const,
	SURVEY_SERVICE: 'surveyservice' as const,
	DEPARTMENT_SERVICE: 'departmentservice' as const,
	POSITION_SERVICE: 'positionservice' as const,
	COMPANY_SERVICE: 'companyservice' as const,
	GATEWAY_SERVICE: 'gateway' as const,
	COMPANY_STRUCTURE_SERVICE: 'companystructureservice' as const,
	EVENT_SERVICE: 'eventservice' as const,
	PROJECT_SERVICE: 'projectservice' as const,
	FILTER_SERVICE: 'filterservice' as const,
	ADMIN_SERVICE: 'adminservice' as const,
	EDUCATION_SERVICE: 'educationservice' as const,
	FAMILY_SERVICE: 'familyservice' as const,
	FEEDBACK_SERVICE: 'feedbackservice' as const,
	CLAIM_SERVICE: 'claimservice' as const,
	VACATION_SERVICE: 'vacationservice' as const,
};

export enum RequestStatus {
	still = 'STILL',
	loading = 'LOADING',
	success = 'SUCCESS',
	failed = 'FAILED',
}

export interface APIOptions {
	noContentType?: boolean;
}
