import { useState } from 'react';
import { claim_serviceAPI } from 'src/app/redux/queries/claim-service/claim_serviceAPI';
import { useAppDispatch } from 'src/app/redux/utils';
import { ConfirmModal } from 'src/entities/_modals/ConfirmModal/ConfirmModal';
import { DropDownMenu } from 'src/shared/ui/DropDownMenu/DropDownMenu';
import { ReactComponent as DeleteSVG } from 'src/shared/assets/svg_icons/action/delete.svg';

interface Props {
	id: string;
	isRemoveFlag: boolean;
	setIsRemoveFlag: (item: boolean) => void;
}

export const RemoveClaim = (props: Props) => {
	const {
		id, //
		isRemoveFlag,
		setIsRemoveFlag,
	} = props;

	const dispatch = useAppDispatch();
	const [claimId, setClaimId] = useState<string | null>(null);
	const [isDeleteModal, setIsDeleteModal] = useState(false);
	const [deleteClaim, { isLoading: isDeleteLoading }] = claim_serviceAPI.useDeleteClaimByClaimIdMutation();

	const DROP_DOWN_ITEMS = [
		{
			icon: <DeleteSVG />,
			title: 'Удалить',
			action: (id?: string | null) => {
				if (!id) return;
				setClaimId(id);
				toggleModalForm();
				removeClaim();
			},
		},
	];

	const toggleModalForm = () => {
		setIsDeleteModal(!isDeleteModal);
	};

	const removeClaim = () => {
		if (!claimId) return;

		deleteClaim({
			claimId: claimId,
		})
			.unwrap()
			.then(() => {
				setIsRemoveFlag(!isRemoveFlag);
				toggleModalForm();
				dispatch(claim_serviceAPI.util.resetApiState());
			})
			.catch(error => console.log(error));
	};

	// * Render
	return (
		<>
			{isDeleteModal && (
				<ConfirmModal
					title="Удалить заявку?"
					description="Вы действительно хотите удалить эту заявку/категорию?"
					primaryText="Удалить"
					isLoading={isDeleteLoading}
					isOpen={isDeleteModal}
					onClose={toggleModalForm}
					primaryOnClick={() => removeClaim()}
				/>
			)}
			<DropDownMenu
				value={id}
				items={DROP_DOWN_ITEMS}
			/>
		</>
	);
};
