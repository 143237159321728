import cn from 'classnames';
import { ComponentPropsWithoutRef, PropsWithChildren } from 'react';
import { ReactComponent as SearchSVG } from 'src/shared/assets/svg/action/search.svg';
import { SelectSingleWithSearch } from 'src/shared/ui/_inputs/selects/SelectSingleWithSearch';
import { TextInput } from 'src/shared/ui/_inputs/text_Inputs/TextInput';
import s from './SelectStringSearchFilter.module.scss';
import { SelectValue } from './types';

interface Props<T extends string> extends ComponentPropsWithoutRef<'div'> {
	selectPlaceholder: string;
	selectValue: SelectValue<T>;
	setSelect: (value: SelectValue<T>) => void;
	selectOptions: Array<SelectValue<T>>;
	keyNames?: {
		name: keyof SelectValue<T>;
		value: keyof SelectValue<T>;
		tooltipText?: string;
	};
	searchPlaceholder: string;
	searchStringValue: string;
	setSearchString: (value: string) => void;
}

export const SelectStringSearchFilter = <T extends string>(props: PropsWithChildren<Props<T>>) => {
	const {
		selectPlaceholder,
		selectValue,
		setSelect,
		selectOptions,
		keyNames = { name: 'name', value: 'id' },
		searchPlaceholder,
		searchStringValue,
		setSearchString,
		className,
	} = props;

	// * Render
	return (
		<div className={cn(s.container, className)}>
			<SelectSingleWithSearch
				placeholder={selectPlaceholder}
				keyNames={keyNames}
				selectedOption={selectValue}
				setSelectedOption={setSelect}
				options={selectOptions}
			/>

			<TextInput
				value={searchStringValue}
				placeholder={searchPlaceholder}
				onChange={event => setSearchString(event.currentTarget.value)}
				RightIcon={<SearchSVG />}
			/>
		</div>
	);
};
