import { FloatingFocusManager } from '@floating-ui/react';
import { DateTime, MonthNumbers } from 'luxon';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { useFloatingWrapper } from 'src/shared/hooks/useFloatingWrapper';
import { monthToRuMonth } from 'src/shared/lib/date';
import s from './DatePickerHeader.module.scss';

interface Props {
	selectedDate: Date;
	setSelectedDate: (date: Date) => void;
	noPrevious?: boolean; // ! Временная переменная для тестирования возможности смотреть предыдущие брони
}

export const DatePickerHeader: React.FC<Props> = props => {
	const {
		selectedDate, // TODO: SelectedDate выступает так же как минимальная возможная дата для выбора. Надо задавать минимальную дату для выбора пропсом minDate?.
		setSelectedDate,
		noPrevious = false,
	} = props;

	// * Const
	const MIN_DATE = new Date();

	const [isOpen, setIsOpen] = useState(false);

	const handleOnChange = (date: Date | null) => {
		setIsOpen(!isOpen);
		date && setSelectedDate(date);
	};

	const getDateString = () => {
		const luxonDate = DateTime.fromJSDate(selectedDate);
		return `${luxonDate ? `${luxonDate.day} ${monthToRuMonth(luxonDate.month as MonthNumbers)} ${luxonDate.year}` : ''}`;
	};

	const toPreviousDay = () => {
		const minDate = DateTime.fromJSDate(MIN_DATE).startOf('day').toJSDate();
		const nextDate = DateTime.fromJSDate(selectedDate).minus({ day: 1 }).toJSDate();
		if (noPrevious) {
			if (nextDate > minDate) {
				setSelectedDate(nextDate);
			}
		} else {
			setSelectedDate(nextDate);
		}
	};

	const toNextDay = () => {
		const nextDate = DateTime.fromJSDate(selectedDate).plus({ day: 1 }).toJSDate();
		setSelectedDate(nextDate);
	};

	// * Floating
	const { floatingStyles, refs, context, getReferenceProps, getFloatingProps, headingId } = useFloatingWrapper(isOpen, setIsOpen);

	// * Render
	return (
		<>
			<div className={s.container}>
				<button
					className={s.button}
					onClick={toPreviousDay}
				>
					&lt;
				</button>

				<div
					className={s.date_string}
					ref={refs.setReference}
					{...getReferenceProps()}
					onClick={event => {
						event.preventDefault();
						setIsOpen(prevState => !prevState);
					}}
				>
					<button className={s.button}>{getDateString()}</button>
				</div>

				<button
					className={s.button}
					onClick={toNextDay}
				>
					&gt;
				</button>
			</div>

			{isOpen && (
				<FloatingFocusManager
					context={context}
					modal={false}
				>
					<div
						ref={refs.setFloating}
						style={{
							...floatingStyles,
							zIndex: 'var(--z-index-floating)',
						}}
						aria-labelledby={headingId}
						{...getFloatingProps()}
					>
						<DatePicker
							locale={'ru'}
							selected={selectedDate}
							onChange={handleOnChange}
							minDate={noPrevious ? MIN_DATE : undefined}
							inline
						/>
					</div>
				</FloatingFocusManager>
			)}
		</>
	);
};
