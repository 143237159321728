import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGetGroupsQuery, usePostImportDepartmentsByDepartmentIdMutation } from 'src/app/redux/queries/vacation-service/vacation_serviceAPI';
import { useAppSelector } from 'src/app/redux/utils';
import { useRights } from 'src/shared/hooks/useRights';
import { LineTabs } from 'src/shared/ui/_tabs/lineTabs';
import { SingleTab } from 'src/shared/ui/_tabs/lineTabs/LineTabs';
import s from './Groups.module.scss';

export const Groups = () => {
	// * Routing
	const [searchParams, setSearchParams] = useSearchParams();
	const groupParam = searchParams.get('group');

	// * Selector
	const department = useAppSelector(state => state.user_service.user.userInfo?.departmentUser?.department);

	const isAdmin = useRights();
	const [isFirstClick, setIsFirstClick] = useState(true);

	// ! У пользователя не может не быть департамента
	if (department === undefined) {
		return null;
	}

	// * Query
	const { data } = useGetGroupsQuery({});
	const [getDepartment, { data: departmentData }] = usePostImportDepartmentsByDepartmentIdMutation();

	useEffect(() => {
		getDepartment({
			departmentId: department.id,
		});
	}, []);

	useEffect(() => {
		if (!isFirstClick) localStorage.setItem('vacationGroupId', String(groupParam));
	}, [groupParam]);

	const groups = data?.body;

	const userDepartmentId = department.id;

	if (groupParam === 'null' && groups && groups.length) {
		setSearchParams(prev => {
			return {
				...Object.fromEntries(prev.entries()),
				group: groups[0].id,
			};
		});
	}

	const customGroupTabs: SingleTab[] = [];
	const departmentRelatedGroupTabs: SingleTab[] = [];

	groups?.forEach(group => {
		const tab = {
			id: group.id,
			name: group.shortName,
		};

		if (isAdmin) {
			customGroupTabs.push(tab);
		} else {
			if (group.departmentId === null) {
				customGroupTabs.push(tab);
			} else if (group.departmentId === userDepartmentId) {
				departmentRelatedGroupTabs.push(tab);
			}
		}
	});

	const tabs = [...departmentRelatedGroupTabs, ...customGroupTabs];

	const onTabClick = (id: string) => {
		setSearchParams(prev => {
			return {
				...Object.fromEntries(prev.entries()),
				group: id,
			};
		});
		if (isFirstClick) setIsFirstClick(false);
	};

	// * Render
	return (
		<>
			{groupParam && (
				<LineTabs
					className={s.container}
					tabs={tabs}
					activeTabId={groupParam}
					setActiveTabId={onTabClick}
				/>
			)}
		</>
	);
};
