import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import cn from 'classnames';
import { FC, useEffect, useState } from 'react';
import s from './CardTable.module.scss';
import { UsersTableDataItem } from '../Users/consts/COLUMNS_USERS';
import { useRights } from 'src/shared/hooks/useRights';

interface Props {
	columns: ColumnDef<any, any>[];
	data: UsersTableDataItem[];
	onRowClick?: (rowId: number) => void;
}

export const CardTable: FC<Props> = props => {
	const {
		columns, //
		data,
		onRowClick,
	} = props;

	// * Rights
	const isAdmin = useRights();

	const [users, setUsers] = useState(data);

	useEffect(() => {
		setUsers(data);
	}, [data]);

	const table = useReactTable({
		data: users,
		columns,
		getCoreRowModel: getCoreRowModel(),
	});

	// * Style
	const gridStyle = isAdmin ? `48px repeat(${columns.length - 2}, 1fr) 24px` : `48px repeat(${columns.length - 1}, 1fr)`;

	//* Render
	return (
		<div className={s.container}>
			<div className={s.table}>
				{table.getHeaderGroups().map(headerGroup => (
					<div
						key={headerGroup.id}
						style={{ gridTemplateColumns: gridStyle }}
						className={cn(s.table_row, s.header_wrapper)}
					>
						{headerGroup.headers.map(header => (
							<div
								className={s.table_header}
								key={header.id}
							>
								{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
							</div>
						))}
					</div>
				))}

				{table.getRowModel().rows.map(row => (
					<div
						key={row.id}
						style={{ gridTemplateColumns: gridStyle }}
						className={cn(s.table_row, s.cell_card)}
						onClick={event => {
							if (onRowClick) {
								event.stopPropagation();
								onRowClick(+row.id);
							}
						}}
					>
						{row.getVisibleCells().map(cell => (
							<div
								className={s.table_cell}
								key={cell.id}
							>
								{flexRender(cell.column.columnDef.cell, cell.getContext())}
							</div>
						))}
					</div>
				))}
			</div>
		</div>
	);
};
