import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { PageDecorator } from 'src/app/decorators/page_decorators';
import { claim_serviceAPI } from 'src/app/redux/queries/claim-service/claim_serviceAPI';
import { ClaimPriority } from 'src/app/redux/queries/claim-service/types/types';
import { useGetDepartmentFindQuery } from 'src/app/redux/queries/department-service/department_serviceAPI';
import { file_serviceAPI } from 'src/app/redux/queries/file-service/file_serviceAPI';
import { user_serviceAPI } from 'src/app/redux/queries/user-service/user_serviceAPI';
import { routerPath } from 'src/app/router/paths';
import { PageTitle } from 'src/entities/PageTitle/PageTitle';
import { dateToBackendISO } from 'src/shared/lib/date';
import { Button } from 'src/shared/ui/_buttons/Button';
import { DateField } from 'src/shared/ui/_fields/DateField';
import { SelectSingleField } from 'src/shared/ui/_fields/SelectSingleField';
import { StringField } from 'src/shared/ui/_fields/StringField';
import { Heading } from 'src/shared/ui/Heading';
import * as yup from 'yup';
import { OPTION_PRIORITY } from '../../../../consts/consts';
import { type FormData } from '../../../../types';
import { ClaimsDropZone } from '../ClaimsDropZone/ClaimsDropZone';
import s from './ClaimFormPage.module.scss';

const CURRENT_DATE = new Date();

const schema = yup.object().shape({
	topic: yup.string().min(1, 'Минимум 1 символ').max(50, 'Максимум 50 символов').required('Обязательное поле'),
	description: yup.string().min(1, 'Минимум 1 символ').max(500, 'Максимум 500 символов').required('Обязательное поле'),
	leader: yup
		.object()
		.nullable()
		.shape({
			id: yup.string().required(''),
			name: yup.string().required(''),
		})
		.required('Обязательное поле'),
	optionPriority: yup
		.object()
		.nullable()
		.shape({
			id: yup.string().required(''),
			name: yup.string().required(''),
		})
		.required('Обязательное поле'),
});

export const ClaimFormPage = () => {
	const navigate = useNavigate();

	const formMethods = useForm<FormData>({
		defaultValues: {
			topic: '',
			description: '',
			departmnet: { id: '', name: '' },
			categories: { id: '', name: '' },
			leader: { id: '', name: '' },
			optionPriority: { id: '', name: '' },
			date: null,
			files: [],
		},
		resolver: yupResolver(schema),
	});

	const { handleSubmit } = formMethods;

	// * Nav
	const navigateToApplication = () => {
		navigate(routerPath.claim.page);
	};

	// * Api
	const { data: departmentData } = useGetDepartmentFindQuery({
		skipcount: 0,
		takecount: 1000,
	});
	const [postClaims] = claim_serviceAPI.usePostClaimsMutation();
	const [createFile] = file_serviceAPI.usePostFileCreateMutation();

	const departments = departmentData?.body ?? [];

	const departmentAll = departments.map(department => ({
		id: department.id,
		name: department.shortName,
		fullName: department.name,
	}));

	const { currentData: categoriesTreeData } = claim_serviceAPI.useGetCategoriesQuery({
		skipcount: 0,
		takecount: 1000,
	});

	const { data: usersTreeData } = user_serviceAPI.useGetUserFindQuery({
		skipcount: 0,
		takecount: 1000,
		includedepartments: true,
		isactive: true,
	});

	// * Categories
	const categories =
		categoriesTreeData?.body?.map(item => ({
			id: item.id,
			name: item.name,
		})) || [];

	// * Managers
	const managers =
		usersTreeData?.body
			?.filter(item => {
				return item.departmentUser?.role === 'Manager';
			})
			.map(item => {
				const { firstName, lastName, middleName } = item.user;
				return {
					id: item.user.id,
					name: `${firstName} ${middleName || ''} ${lastName}`,
				};
			}) || [];

	// * Submit
	const onSubmit: SubmitHandler<FormData> = (data: FormData) => {
		const { topic, description, departmnet, categories, leader, optionPriority, date, files } = data;

		const filesData = new FormData();
		for (const newFile of files) {
			filesData.append('uploadedFiles', newFile);
		}

		postClaims({
			createClaimCommand: {
				name: topic,
				content: description,
				categoryId: categories?.id || null,
				managerUserId: leader.id,
				departmentId: departmnet?.id || null,
				...(optionPriority?.id && { priority: (optionPriority?.id as ClaimPriority) || null }),
				deadline: date ? dateToBackendISO(date) : null,
			},
		})
			.unwrap()
			.then(claimId => {
				files.length > 0 &&
					createFile({
						createFilesRequest: {
							accessType: 'Public',
							entityId: claimId,
							fileSource: 'Claim',
							uploadedFiles: filesData,
						},
					});
				navigate(routerPath.claim.page);
			})
			.catch(err => {
				console.log(err);
			});
	};

	// * Render
	return (
		<PageDecorator>
			<PageTitle
				title="Система заявок"
				isBeta
				buttons={[
					<Button
						key={0}
						variant="tertiary"
						onClick={navigateToApplication}
					>
						Отменить
					</Button>,
					<Button
						key={1}
						type="submit"
						form="uniqueId"
					>
						Отправить заявку
					</Button>,
				]}
			/>

			<FormProvider {...formMethods}>
				<form
					id="uniqueId"
					onSubmit={handleSubmit(onSubmit)}
					className={s.container}
				>
					<Heading
						level={2}
						size={3}
						className={s.title}
					>
						Новая заявка
					</Heading>
					<StringField
						name="topic"
						label="Тема запроса"
						placeholder="Напишите название темы запроса"
						maxLength={50}
						required
					/>
					<StringField
						name="description"
						label="Описание"
						placeholder="Напишите описание к вашей заявке"
						maxLength={500}
						required
					/>
					<div className={s.form_item_select}>
						<SelectSingleField
							name="departmnet"
							label="Департамент"
							placeholder="Выберите из списка"
							options={departmentAll}
							keyNames={{
								name: 'name',
								value: 'id',
								tooltipText: 'fullName',
							}}
						/>
						<SelectSingleField
							name="categories"
							label="Категория запроса"
							placeholder="Выберите из списка"
							options={categories}
						/>
					</div>
					<div className={s.form_item_select}>
						<SelectSingleField
							name="leader"
							label="Руководитель"
							options={managers}
							required
						/>

						<div className={s.form_item_select_data_time}>
							<SelectSingleField
								name="optionPriority"
								label="Приоритетность"
								placeholder={OPTION_PRIORITY[1].name}
								options={OPTION_PRIORITY}
							/>

							<DateField
								name="date"
								label="Ожидаемая дата окончания"
								placeholderText="ДД.ММ.ГГ"
								minDate={CURRENT_DATE}
							/>
						</div>
					</div>
					<ClaimsDropZone name="files" />
				</form>
			</FormProvider>
		</PageDecorator>
	);
};
