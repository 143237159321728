import cn from 'classnames';
import { FC } from 'react';
import { CollapseArrows } from 'src/shared/ui/CollapseArrows';
import { CELL_GAP, CELL_SIZE, COLUMNS_META, DIVIDER_WIDTH } from '../../consts';
import { SortBy } from '../../types';
import { User } from './types';
import s from './UsersTable.module.scss';

interface Props {
	users: User[];
	sortBy: { ascending: boolean; by: SortBy } | null;
	setSortBy: (sortBy: { ascending: boolean; by: SortBy } | null) => void;
}

export const UsersTable: FC<Props> = props => {
	const {
		users, //
		sortBy,
		setSortBy,
	} = props;

	const onHeaderClick = (columnId: SortBy | 'days') => {
		if (columnId !== 'days') {
			if (sortBy?.by === columnId) {
				setSortBy({ ...sortBy, ascending: !sortBy.ascending });
			} else {
				setSortBy({ by: columnId, ascending: true });
			}
		}
	};

	// * Render
	return (
		<div className={s.container}>
			{COLUMNS_META.map(column => (
				<div
					key={column.id}
					className={s.column}
					style={{ width: column.width + 'rem' }}
				>
					<div className={s.header}>
						<div className={s.row} />
						<div
							className={cn(s.row, s.row__header, column.id !== 'days' && s.row_clickable)}
							onClick={() => onHeaderClick(column.id)}
						>
							{column.name}
							{column.id !== 'days' && (
								<CollapseArrows
									isOpen={sortBy?.by === column.id && sortBy.ascending ? true : false}
									arrowType="empty"
								/>
							)}
						</div>
					</div>

					{column.id === 'days'
						? users.map(user => (
								<div
									key={user.id}
									className={s.row}
								>
									<div className={cn(s.cell, s.cell__day, s.cell_clickable)}>{user.days.left}</div>
									<div className={cn(s.cell, s.cell__day)}>{user.days.total}</div>
									<div className={cn(s.cell, s.cell__day)}>{user.days.used}</div>
								</div>
							))
						: users.map(user => (
								<div
									key={user.id}
									className={cn(s.cell, column.id === 'name' && s.cell_clickable)}
								>
									{/*  @ts-ignore */}
									{user[column.id]}
								</div>
							))}
				</div>
			))}

			<div
				className={s.divider}
				style={{
					height: users.length * CELL_SIZE + (users.length - 1) * CELL_GAP + 'rem',
					width: DIVIDER_WIDTH + 'rem',
				}}
			/>
		</div>
	);
};
