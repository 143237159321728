import React, { ReactElement } from 'react';
import s from './RoomsList.module.scss';
import { OfficeRoom } from 'src/app/redux/state/office/office/types';
import { RoomCard } from '../RoomCard/RoomCard';

interface Props {
	rooms: OfficeRoom[];
	getDropDownMenu?: (roomId: string) => ReactElement;
	onClick?: (room: OfficeRoom) => void;
}

export const RoomsList: React.FC<Props> = props => {
	const { rooms, getDropDownMenu, onClick } = props;

	const onClickHandler = (room: OfficeRoom) => {
		if (!onClick) {
			return undefined;
		} else {
			return () => onClick(room);
		}
	};

	// * Render
	return (
		<div className={s.container}>
			<div className={s.header}>
				<span>НАЗВАНИЕ ПОМЕЩЕНИЯ</span>
				<span>ТИП ПОМЕЩЕНИЯ</span>
				<span>КОЛИЧЕСТВО МЕСТ</span>
				<span>ВРЕМЯ РАБОТЫ</span>
			</div>

			<div className={s.list}>
				{rooms &&
					rooms.map(room => (
						<RoomCard
							key={room.room.id}
							room={room}
							DropDownMenu={getDropDownMenu && getDropDownMenu(room.room.id)}
							onClick={onClickHandler(room)}
						/>
					))}
			</div>
		</div>
	);
};
