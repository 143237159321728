import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetUserFindQuery } from 'src/app/redux/queries/user-service/user_serviceAPI';
import { UserListItem } from 'src/app/redux/queries/vacation-service/types/types';
import { useGetGroupsByGroupIdQuery, usePatchGroupsByGroupIdMutation } from 'src/app/redux/queries/vacation-service/vacation_serviceAPI';
import { createFullName } from 'src/shared/lib/string';
import { AddButton } from 'src/shared/ui/_buttons/AddButton';
import { LoaderCircle } from 'src/shared/ui/_loaders/LoaderCircle/LoaderCircle';
import { AddUserContainer } from '../../../AddUserContainer/AddUserContainer';
import s from '../../styles/Content.module.scss';
import { UsersTable } from '../UsersTable/UsersTable';

export const TabUsers = () => {
	const { groupId } = useParams() as { groupId: string };
	const [users, setUsers] = useState<UserListItem[]>([]);

	// * Modal
	const [showModal, setShowModal] = useState(false);
	const toggleModal = () => setShowModal(prevState => !prevState);

	// * API
	const [editUsers, { isLoading: editLoading }] = usePatchGroupsByGroupIdMutation();

	const { data: groupData, refetch } = useGetGroupsByGroupIdQuery({
		groupId,
	});

	const { data: usersData, isLoading: isLoadingGetUsers } = useGetUserFindQuery({
		skipcount: 0,
		takecount: 1000,
		includedepartments: true,
		isactive: true,
		includecurrentavatar: true,
		includepositions: true,
	});

	const userIds = groupData?.body?.users?.map(user => user.id) || [];

	const allUsers =
		usersData?.body?.map(item => ({
			id: item.user.id,
			name: createFullName({ firstName: item.user.firstName, lastName: item.user.lastName, middleName: item.user.middleName ?? undefined }),
			avatar: item.user.avatar,
			department: item.departmentUser?.department.name,
			position: item.positionUser?.name,
		})) || [];

	useEffect(() => {
		setUsers(allUsers.filter(user => userIds.find(userId => user.id === userId)));
	}, [groupData, isLoadingGetUsers]);

	const onDelete = (userId: string) => {
		editUsers({
			groupId,
			body: [
				{
					op: 'remove',
					value: [userId],
					path: '/UserIds',
				},
			],
		})
			.unwrap()
			.catch(e => console.log(e))
			.finally(() => refetch());
	};

	const onEdit = (newUserIds: string[]) => {
		editUsers({
			groupId,
			body: [
				{
					op: 'replace',
					value: [...userIds, ...newUserIds],
					path: '/UserIds',
				},
			],
		})
			.unwrap()
			.then(() => toggleModal())
			.catch(e => console.log(e))
			.finally(() => refetch());
	};

	// * Render
	return (
		<>
			{showModal && (
				<AddUserContainer
					showModal={showModal}
					toggleModal={toggleModal}
					editAction={onEdit}
					editLoading={editLoading || isLoadingGetUsers}
					allUsers={allUsers.filter(user => !userIds.find(existingUserId => existingUserId === user.id))}
				/>
			)}
			<div>
				<AddButton
					title="Добавить сотрудников"
					onClick={toggleModal}
					className={s.add_button_users}
				/>
				{isLoadingGetUsers ? (
					<LoaderCircle />
				) : (
					<UsersTable
						users={users}
						onDeleteUser={onDelete}
					/>
				)}
			</div>
		</>
	);
};
