export const PATHS = {
	name: '/Name',
	address: '/Address',
	description: '/Description',
	date: '/Date',
	endDate: '/EndDate',
	format: '/Format',
	access: '/Access',
	isActive: '/IsActive',
} as const;

export type PathKeys = keyof typeof PATHS;
export type PathValues = (typeof PATHS)[PathKeys];

export const ACCESS = [{ id: 'opened' }, { id: 'closed' }];

export const MAX_STEPS = 3;
export const FORM_NAMES = ['eventCreation', 'addFilesToEvent', 'addUsersToEvent'];
