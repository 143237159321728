import s from './StatisticsPie.module.scss';
import { ResponsivePie } from '@nivo/pie';
import { color } from 'src/shared/lib/color';
import { COLORS } from '../../../../consts/consts';
import { StatisticsLegends } from '../StatisticsLegends/StatisticsLegends';

interface StatisticsElements {
	id: string;
	label: string;
	value: number;
}

export const StatisticsPie = ({ data }: { data: StatisticsElements[] | undefined }) => {
	// * Libs
	const { getColors } = color;
	const countValues = data ? data.length : 0;

	// * Render
	return (
		<div className={s.container}>
			<div className={s.pie_wrapper}>
				{data && (
					<ResponsivePie
						data={data}
						colors={getColors(countValues, COLORS)}
						margin={{ top: 6, right: 6, bottom: 6, left: 6 }}
						innerRadius={0.02}
						startAngle={0}
						padAngle={2}
						cornerRadius={0}
						activeOuterRadiusOffset={6}
						borderColor="#FDFEFF"
						arcLabelsTextColor="#FAFAFA"
						enableArcLinkLabels={false}
						theme={{
							labels: {
								text: {
									fontSize: '1rem',
									lineHeight: '1.45rem',
									fontFamily: 'Lato, Arial, serif',
									fontWeight: '400',
								},
							},
						}}
						valueFormat={value => (value > 0 ? `${value}%` : '')}
						tooltip={({ datum: { label, color } }) => (
							<div className={s.pie_tooltip_container}>
								<div className={s.pie_tooltip_wrapper}>
									<div
										className={s.dot}
										style={{ background: color }}
									/>
									<div>{label}</div>
								</div>
							</div>
						)}
					/>
				)}
			</div>
			<div className={s.legends_wrapper}>
				<StatisticsLegends data={data} />
			</div>
		</div>
	);
};
