import { useState } from 'react';
import { useGetCategoryFindQuery, useLazyGetEventFindQuery } from 'src/app/redux/queries/event-service/event_serviceAPI';
import { useRights } from 'src/shared/hooks/useRights';
import { ConditionalRendering } from 'src/shared/providers';
import { LineTabs } from 'src/shared/ui/_tabs/lineTabs';
import { SingleTab } from 'src/shared/ui/_tabs/lineTabs/LineTabs';
import { EmptyData } from 'src/shared/ui/EmptyData/EmptyData';
import { Categories } from 'src/widgets/Categories';
import { useGetCategoryActions } from '../../hooks/useGetCategoryActions';
import { EventsContent } from '../EventsContent/EventsContent';
import { FilterEvents } from '../FilterEvents/FilterEvents';
import s from './EventList.module.scss';

export const EventList = () => {
	const isAdmin = useRights();

	// * Tabs
	const tabs: SingleTab[] = [
		{
			id: 'events',
			name: 'События',
		},
		{
			id: 'past',
			name: 'Прошедшие',
		},
	];

	if (isAdmin)
		tabs.push({
			id: 'archive',
			name: 'Архив',
		});

	const [activeTabId, setActiveTabId] = useState<string>(tabs[0].id);
	const [isLoading, setIsLoading] = useState(false);
	const [showAllMonths, setShowAllMonths] = useState(true);
	const [isCurrentYear, setIsCurrentYear] = useState(false);
	const [categoriesIds, setCategoriesIds] = useState<string[]>([]);

	// * API
	const { data: categoriesData } = useGetCategoryFindQuery({
		skipcount: 0,
		takecount: 100,
	});

	const categories =
		categoriesData?.body?.map(category => ({
			...category,
			color: category.color ? parseInt(category.color.slice(-2)) - 1 : 0,
		})) || [];

	const [filterEvents, { data: eventsData, isLoading: filterLoading }] = useLazyGetEventFindQuery();

	const events =
		eventsData?.body?.map(event => ({
			...event,
			date: new Date(event.date),
			endDate: event.endDate ? new Date(event.endDate) : null,
		})) || [];

	const archiveEvents = events.filter(e => !e.isActive);

	// - Category actions
	const { addCategory, editCategory, removeCategory, isLoading: categoriesLoading } = useGetCategoryActions();

	// * Render
	return (
		<div className={s.wrapper}>
			<Categories
				categories={categories}
				getCategoryId={removeCategory}
				getCategoryInfo={editCategory}
				getNewCategoryInfo={addCategory}
				isLoading={categoriesLoading}
				setCategoriesIds={setCategoriesIds}
			/>
			<div>
				<FilterEvents
					filterEvents={filterEvents}
					activeTabId={activeTabId}
					setIsLoading={setIsLoading}
					categoriesIds={categoriesIds}
					setShowAllMonths={setShowAllMonths}
					setIsCurrentYear={setIsCurrentYear}
				/>
				<LineTabs
					tabs={tabs}
					className={s.line_tabs}
					activeTabId={activeTabId}
					setActiveTabId={setActiveTabId}
				/>

				<ConditionalRendering
					initialLoading={filterLoading || isLoading}
					isSuccessful={events.length > 0}
					LoadedResult={
						<EventsContent
							events={activeTabId === 'archive' ? archiveEvents : events}
							activeTabId={activeTabId}
							showAllMonths={showAllMonths}
							isCurrentYear={isCurrentYear}
						/>
					}
					FailedResult={<EmptyData />}
				/>
			</div>
		</div>
	);
};
