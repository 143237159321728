import { flexRender, useReactTable, getCoreRowModel } from '@tanstack/react-table';
import s from './Table.module.scss';
import { ReactNode } from 'react';
import { COLUMNS_SENDERS } from './consts';
import { useClaimContext } from '../../context/context';
import cn from 'classnames';

export interface TABLE_DATA {
	id: string;
	sender: ReactNode;
	name: string;
	status: string | number | undefined;
	priority: string | number | undefined;
	department: string;
	executor: string;
	dropdown?: ReactNode;
}

interface Props {
	onRowClick?: (rowId: number) => void;
}

export const Table = ({ onRowClick }: Props) => {
	const { claims } = useClaimContext();

	const table = useReactTable({
		data: claims || [],
		columns: COLUMNS_SENDERS,
		getCoreRowModel: getCoreRowModel(),
	});

	return (
		<div>
			<div className={s.table}>
				{table.getHeaderGroups().map(item => (
					<div
						key={item.id}
						className={s.table_column}
					>
						{item.headers.map(item => (
							<div key={item.id}>{item.isPlaceholder ? null : flexRender(item.column.columnDef.header, item.getContext())}</div>
						))}
					</div>
				))}
				{table.getRowModel().rows.map(row => (
					<div
						key={row.id}
						className={cn(s.table_row, s.cell_card)}
						onClick={event => {
							if (onRowClick) {
								event.stopPropagation();
								onRowClick(+row.id);
							}
						}}
					>
						{row.getVisibleCells().map(cell => (
							<div key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
						))}
					</div>
				))}
			</div>
		</div>
	);
};
