import React, { DetailedHTMLProps, HTMLAttributes, PropsWithChildren, ReactNode } from 'react';
import { FixedSizeList as List, ListChildComponentProps, ListOnScrollProps } from 'react-window';
import s from './OptionsWindow.module.scss';
import { useWindowDimensions } from 'src/shared/hooks/useWindowDimensions';
import { Tooltip } from '../../_tooltips/Tooltip/Tooltip';
import { getItemSize } from './_helpers';

interface Props<T, Key> extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	keyNames: {
		name: Key;
		value: Key;
		tooltipText?: string;
	};
	options: T[];
	selectedOptions?: T[]; // ! Used in single/multi select only
	onOptionClick: (option: T) => void;
	width?: string;

	getScroll?: (getScroll: ListOnScrollProps) => void;
	scrollToItem?: number;
	fixedOption?: T;
	onFixedOptionClick?: () => void;
}

export const OptionsWindow = <T extends Record<string, any> & { Icon?: ReactNode }, Key extends keyof T>(props: PropsWithChildren<Props<T, Key>>) => {
	const {
		className, //
		keyNames,
		options,
		onOptionClick,
		width = '100%',
		selectedOptions,
		getScroll,
		scrollToItem = 0,
		fixedOption,
		...divProps
	} = props;

	const isSelected = (index: number) => selectedOptions?.find(option => option[keyNames.value] === options[index][keyNames.value]);

	const Row = ({ index, style }: ListChildComponentProps) => (
		<div
			className={`${s.list_item} ${isSelected(index) && s.selected_item}`}
			onClick={() => onOptionClick(options[index])}
			onKeyDown={event => event.key === 'Enter' && onOptionClick(options[index])}
			style={{
				...style,
				flex: 1,
			}}
		>
			{options[index].Icon && options[index].Icon}
			<span className={s.list_item_text}>
				<Tooltip text={options[index][keyNames.tooltipText ? keyNames.tooltipText : keyNames.name]}>
					<span>{options[index][keyNames.name]}</span>
				</Tooltip>
			</span>
		</div>
	);

	const { width: screenWidth } = useWindowDimensions();
	const itemCount = options ? options.length : 0;
	const itemSize = getItemSize(screenWidth);
	const maxItemsInWindow = 6;

	const noScroll = itemCount <= maxItemsInWindow;

	const getWindowHeight = () => {
		if (noScroll) {
			return itemCount * itemSize;
		} else {
			return maxItemsInWindow * itemSize;
		}
	};

	// * Render
	return (
		<div
			className={`${s.container} ${className}`}
			style={{ width: width, display: itemCount === 0 ? 'none' : 'block' }}
			{...divProps}
		>
			{fixedOption && (
				<div
					className={`${s.list_item}`}
					// onClick={() => onOptionClick(options[index])}
					// onKeyDown={event => event.key === 'Enter' && onOptionClick(options[index])}
				>
					<span className={s.list_item_text}>
						<Tooltip
							text={fixedOption.name}
							style={{
								display: 'flex',
								alignItems: 'center',
							}}
						>
							{fixedOption.icon}
							<span>{fixedOption.name}</span>
						</Tooltip>
					</span>
				</div>
			)}

			<List
				className={s.list}
				height={getWindowHeight()}
				itemCount={itemCount}
				itemSize={itemSize}
				width={width} // Minus left + right border & padding.
				onScroll={getScroll}
				initialScrollOffset={noScroll ? 0 : scrollToItem * itemSize} // Only initial.
			>
				{Row}
			</List>
		</div>
	);
};
