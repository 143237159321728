import { FC, ReactNode, useState } from 'react';
import { DepartmentInfo } from 'src/app/redux/queries/department-service/types/types';
import { ButtonPair } from 'src/entities/_buttons/ButtonPair';
import { SelectStringSearchFilter, SelectValue } from 'src/entities/_filters/SelectStringSearchFilter';
import { AddUserList } from 'src/features/_card_lists/AddUserList';
import { matchItems } from 'src/shared/lib/filter';
import { EmptyData } from 'src/shared/ui/EmptyData/EmptyData';
import { Heading } from 'src/shared/ui/Heading';
import s from './AddUsersMC.module.scss';
import { UserListItem } from './types';

interface Props {
	title: string;
	allUsers: UserListItem[];
	allDepartments: DepartmentInfo[];
	setSelectedUsers: (usersIds: string[]) => void;
	isLoading: boolean;
	toggleModal: () => void;
	ExtraComponent?: ReactNode;
	createUsers?: (ids: string[]) => void;
	primaryText?: string;
}

export const AddUsersMC: FC<Props> = props => {
	const {
		title, //
		allUsers,
		allDepartments,
		setSelectedUsers,
		isLoading,
		toggleModal,
		ExtraComponent,
		createUsers,

		primaryText = 'Добавить',
	} = props;

	// * Filters
	const [department, setDepartment] = useState<SelectValue>({ id: null, name: '' });
	const [userName, setUserName] = useState('');

	// - Сохраняем краткие названия департаментов для фильтра
	const departments = allDepartments.map(dep => ({
		id: dep.id,
		name: dep.shortName,
		fullName: dep.name,
	}));

	// - Фильтрация по департаменту
	const filteredUsersByDepartment = allUsers?.filter(user => {
		if (department.name) {
			const departmentName = departments.find(dep => dep.name === department.name)?.fullName;
			return user.department === departmentName;
		}
		return user;
	});

	// - Фильтрация по имени
	const filteredUsers = matchItems(filteredUsersByDepartment, userName);

	// * Temp users state
	const [tempSelectedUsersIds, setTempSelectedUsersIds] = useState<string[]>([]);

	// * Button actions
	const onApply = () => {
		setSelectedUsers(tempSelectedUsersIds);
		if (createUsers) createUsers(tempSelectedUsersIds);
	};

	const onCancel = () => {
		setTempSelectedUsersIds([]);
		toggleModal();
	};

	// * Render
	return (
		<div className={s.container}>
			<Heading level={2}>{title}</Heading>

			{allDepartments && (
				<SelectStringSearchFilter
					selectPlaceholder="Департамент"
					selectValue={department}
					setSelect={(value: SelectValue) => setDepartment(value)}
					selectOptions={departments}
					keyNames={{
						name: 'name',
						value: 'id',
						tooltipText: 'fullName',
					}}
					searchPlaceholder="Введите ФИО сотрудника"
					searchStringValue={userName}
					setSearchString={setUserName}
				/>
			)}

			{ExtraComponent}

			{filteredUsers.length > 0 ? (
				<AddUserList
					users={filteredUsers}
					selectedUsersIds={tempSelectedUsersIds}
					setSelectedUsers={setTempSelectedUsersIds}
				/>
			) : (
				!isLoading && <EmptyData />
			)}

			<ButtonPair
				primaryText={primaryText}
				primaryOnClick={onApply}
				primaryIsLoading={isLoading}
				secondaryText="Отменить"
				secondaryOnClick={onCancel}
				secondaryIsLoading={isLoading}
			/>
		</div>
	);
};
