import { useFormContext } from 'react-hook-form';
import { getIcon } from 'src/pages/claims/lib/getIcon';
import { ReactComponent as DeleteSVG } from 'src/shared/assets/svg_icons/action/delete.svg';
import { formatBytes } from 'src/shared/lib/string';
import { DropZone } from 'src/shared/ui/DropZone/DropZone';
import { v4 } from 'uuid';
import { type File } from '../../../../types';
import s from './ClaimsDropZone.module.scss';

export const ClaimsDropZone = ({ name }: { name: string }) => {
	const { setValue, getValues, watch } = useFormContext();
	const files = watch(name);

	const onDropFiles = async (acceptedFiles: File[]) => {
		const selectedFiles = getValues('files');

		const uploadedFiles = acceptedFiles.map(file => {
			return Object.assign(file, {
				id: `new_${v4()}`,
				parentId: null,
				preview: URL.createObjectURL(file),
			});
		});

		setValue('files', [...selectedFiles, ...uploadedFiles]);
	};

	const deleteFile = (id: string) => {
		const updatedFiles = files.filter((file: File) => file.id !== id);
		setValue('files', updatedFiles);
	};

	// * Render
	return (
		<div>
			<DropZone
				placeholder="Перетащите документы или изображения"
				accept={{ 'image/*': ['.png', '.tiff', '.jpg', '.mpeg', '.avi', '.wmv', '.mp4', '.webm', '.gif'] }}
				onDrop={onDropFiles}
			/>
			{files.length > 0 && (
				<div className={s.all_files}>
					{files.map((file: File) => (
						<div
							className={s.file}
							key={file.name}
						>
							<div className={s.file_info}>
								<div className={s.file_format}>{getIcon(file.name.split('.')[file.name.split('.').length - 1])}</div>
								<div className={s.file_name}>{file.name}</div>
							</div>
							<div className={s.file_size}>{formatBytes(file.size)}</div>
							<div
								className={s.file_delete}
								onClick={() => deleteFile(file.id)}
							>
								<DeleteSVG />
							</div>
						</div>
					))}
				</div>
			)}
		</div>
	);
};
