import s from './CategoryCatalog.module.scss';
import { Heading } from 'src/shared/ui/Heading';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { CategoriesModalForm } from '../CategoriesModalForm/CategoriesModalForm';
import { type Category } from '../../../../../../types';
import { DropDownMenu } from 'src/shared/ui/DropDownMenu/DropDownMenu';
import { ReactComponent as EditSVG } from 'src/shared/assets/svg_icons/action/edit.svg';
import { ReactComponent as DeleteSVG } from 'src/shared/assets/svg_icons/action/delete.svg';
import { claim_serviceAPI } from 'src/app/redux/queries/claim-service/claim_serviceAPI';
import { Button } from 'src/shared/ui/_buttons/Button';
import { ReactComponent as Add } from '../../../../../../../../shared/assets/svg/action/add.svg';
import { ConfirmModal } from 'src/entities/_modals/ConfirmModal/ConfirmModal';
import { useState } from 'react';

export const CategoryCatalog = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [isDeleteModel, setIsDeleteModel] = useState(false);
	const [currentCategory, setCurrentCategory] = useState<Category | null>(null);

	// * Api
	const [removeCategories, { isLoading }] = claim_serviceAPI.useRemoveCategoriesMutation();
	const { data: categoriesData } = claim_serviceAPI.useGetCategoriesQuery({
		skipcount: 0,
		takecount: 100,
	});

	const categories = categoriesData?.body || [];

	const DROP_DOWN_ITEMS = [
		{
			icon: <EditSVG />,
			title: 'Редактировать',
			action: (id?: string) => {
				if (!id) return;
				toggleModalForm();
				defineCurrentCategory(id);
			},
		},
		{
			icon: <DeleteSVG />,
			title: 'Удалить',
			action: (id?: string) => {
				if (!id) return;
				toggleDeleteForm();
				defineCurrentCategory(id);
			},
		},
	];

	// * Action
	const handleDeleteCategory = (id: string) => {
		removeCategories({
			categoryId: id,
		})
			.unwrap()
			.then(() => toggleDeleteForm)
			.catch(error => console.log(error));
	};

	const deleteCategory = () => {
		if (currentCategory) {
			handleDeleteCategory(currentCategory.id);
			toggleDeleteForm();
		}
	};

	const defineCurrentCategory = (id: string) => {
		const category = categories.find(item => item.id === id);
		setCurrentCategory(category ?? null);
	};

	const toggleModalForm = () => {
		setIsOpen(!isOpen);
		setCurrentCategory(null);
	};

	const toggleDeleteForm = () => {
		setIsDeleteModel(!isDeleteModel);
		setCurrentCategory(null);
	};

	// * Render
	return (
		<>
			<div className={s.catalog}>
				<Heading
					level={2}
					size={3}
					className={s.catalog_title}
				>
					Категории
				</Heading>
				{[...categories, { id: 'Без категории', name: 'Без категории' }].map(item => {
					return (
						<div
							key={item.id}
							className={s.catalog_item}
						>
							<span>{item.name}</span>
							{item.id !== 'Без категории' && (
								<div className={s.catalog_dropdown}>
									<DropDownMenu
										value={item?.id}
										items={DROP_DOWN_ITEMS}
									/>
								</div>
							)}
						</div>
					);
				})}
				<Button
					wide
					variant={'secondary'}
					LeftIcon={<Add />}
					className={s.button}
					onClick={toggleModalForm}
				>
					Добавить категорию
				</Button>
			</div>

			{isDeleteModel && (
				<ConfirmModal
					title="Удалить категорию?"
					primaryText="Удалить"
					isLoading={isLoading}
					isOpen={isDeleteModel}
					onClose={toggleDeleteForm}
					primaryOnClick={() => deleteCategory()}
				/>
			)}

			{isOpen && (
				<ModalNewProto
					width="s"
					isOpen={isOpen}
					onClose={toggleModalForm}
				>
					<CategoriesModalForm
						toggleModalForm={toggleModalForm}
						currentCategory={currentCategory}
					/>
				</ModalNewProto>
			)}
		</>
	);
};
