import { Heading } from 'src/shared/ui/Heading';
import s from './ClaimsHistory.module.scss';
import { preparedData } from 'src/pages/claims/lib/preparedData';
import { Avatar } from 'src/shared/ui/Avatar/Avatar';
import { UserAvatar } from 'src/app/redux/state/user/user/types';
import { backendISOUTCToLocalLuxon, tupleToIsoString } from 'src/shared/lib/date';
import { Hours, Minutes } from 'src/shared/lib/date/types';
import { DateTime } from 'luxon';
import { useParams } from 'react-router-dom';
import { claim_serviceAPI } from 'src/app/redux/queries/claim-service/claim_serviceAPI';
import { HistoryElement } from '../HistoryElement/HistoryElement';
import { ClaimId } from '../../ClaimPage/ClaimPage';

interface Props {
	userName: string;
	avatar: UserAvatar | undefined;
	data: string | undefined;
}

export const ClaimsHistory = (props: Props) => {
	const {
		userName, //
		avatar,
		data,
	} = props;
	const { claimId } = useParams() as unknown as ClaimId;

	// * Api
	const { data: claimData } = claim_serviceAPI.useGetClaimsByClaimIdQuery({
		claimId: claimId,
	});

	// * Desc sorted history
	const sortedHistory = (claimData?.changes || []).slice().sort((a, b) => new Date(b.createdAtUtc ?? '').getTime() - new Date(a.createdAtUtc ?? '').getTime());

	// * Date converter
	const formatDate = (dateFormatString: string | undefined, isBackend?: boolean) => {
		const formatDate = isBackend ? backendISOUTCToLocalLuxon(dateFormatString as string) : DateTime.fromISO(dateFormatString as string);
		const newDate = preparedData(data);
		const newTime = tupleToIsoString([formatDate.hour as Hours, formatDate.minute as Minutes]);

		return `${newDate} ${newTime}`;
	};

	// * Render
	return (
		<div className={s.wrapper}>
			<Heading
				level={2}
				size={3}
				className={s.title}
			>
				История заявки
			</Heading>
			<div>
				{sortedHistory.map(item => (
					<HistoryElement
						key={item.id}
						history={item}
					/>
				))}
				<div className={s.creation}>
					<Avatar
						size="extra-small"
						name={userName}
						userAvatar={avatar}
					/>
					<div>
						<p>
							<b>{userName}</b> создал заявку.
						</p>
						<p className={s.data}>{formatDate(data, true)}</p>
					</div>
				</div>
			</div>
		</div>
	);
};
