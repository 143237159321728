import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useGetCategoryFindQuery } from 'src/app/redux/queries/event-service/event_serviceAPI';
import { CategoryInfo } from 'src/app/redux/queries/event-service/types/types';
import { actionsEventCalendar } from 'src/app/redux/state/event-calendar/slice';
import { EventCreation } from 'src/app/redux/state/event-calendar/types';
import { useAppDispatch } from 'src/app/redux/utils';
import { dateTimeStringToISO, isLessThenMax } from 'src/shared/lib/date';
import { DateAndTimeField } from 'src/shared/ui/_fields/DateAndTimeField';
import { SelectValue } from 'src/shared/ui/_fields/SelectSingleCustomValueField';
import { SelectSingleWithFilterField } from 'src/shared/ui/_fields/SelectSingleWithFilterField';
import { StringField } from 'src/shared/ui/_fields/StringField';
import { TextAreaField } from 'src/shared/ui/_fields/TextAreaField';
import { BooleanSwitch } from 'src/shared/ui/_switches/BooleanSwitch/BooleanSwitch';
import * as yup from 'yup';
import { EventCreationHeader } from '../../../../EventCreationHeader/EventCreationHeader';
import { DATE_ERROR, TIME_ERROR } from '../consts';
import { Form, InitialFormValues } from '../types';
import s from './EventMainInfo.module.scss';

interface Props {
	formName: string;
	onSubmitAfterValidation: () => void;
	defaultValues: InitialFormValues;
}

const TODAY_DATE = new Date();

export const EventMainInfo: React.FC<Props> = props => {
	const {
		formName, //
		onSubmitAfterValidation,
		defaultValues,
	} = props;

	const [isClosed, setIsClosed] = useState(defaultValues.access === 'Closed');

	// * Actions
	const dispatch = useAppDispatch();
	const { setEventInfo } = actionsEventCalendar;

	// * API
	const { data } = useGetCategoryFindQuery({
		skipcount: 0,
		takecount: 100,
	});

	const allCategories = data?.body;

	// * Form
	const schema = yup.object().shape({
		name: yup.string().required('Укажите название мероприятия').nullable(),
		// category: yup.object().shape({
		// 	id: yup.string().required('Укажите категорию').nullable(),
		// }),
		startDate: yup.date().required(DATE_ERROR).nullable(),
		startTime: yup.string().required(TIME_ERROR).nullable(),
		endDate: yup.date().required(DATE_ERROR).nullable(),
		endTime: yup.string().required(TIME_ERROR).nullable(),
	});

	const formMethods = useForm<Form>({
		defaultValues,
		resolver: yupResolver(schema),
	});

	const { handleSubmit, control, setError } = formMethods;

	const minEndDate = useWatch({ name: 'startDate', control });

	const onSubmit = (values: Form) => {
		const stageValues: EventCreation = {} as EventCreation;

		const date = dateTimeStringToISO(values.startDate, values.startTime);
		const endDate = dateTimeStringToISO(values.endDate, values.endTime);

		const endDateIsHigher = date && endDate && isLessThenMax(new Date(date), new Date(endDate));

		if (!endDateIsHigher) {
			setError('endDate', { message: 'Дата окончания не может быть меньше даты начала' });
			return;
		}

		if (date && endDate) {
			stageValues.name = values.name;
			stageValues.description = values.description;
			stageValues.address = values.address;
			stageValues.categoriesIds = [values.category.id];
			stageValues.access = isClosed ? 'Closed' : 'Opened';
			stageValues.date = date;
			stageValues.endDate = endDate;

			onSubmitAfterValidation();
			dispatch(setEventInfo(stageValues));
		}
	};

	const formCategoriesForSelect = (categories: CategoryInfo[]): SelectValue[] => {
		return categories.map(category => {
			return { id: category.id, name: category.name };
		});
	};

	// * Render
	return (
		<div>
			<div className={s.header}>
				<EventCreationHeader title="Основная информация" />
				<BooleanSwitch
					text="Закрытое событие"
					checked={isClosed}
					onChange={() => setIsClosed(prevState => !prevState)}
				/>
			</div>

			<form
				className={s.container}
				onSubmit={handleSubmit(onSubmit)}
				id={formName}
			>
				<FormProvider {...formMethods}>
					<div className={s.marginWrapper}>
						<StringField
							name="name"
							label="Название мероприятия"
							placeholder="Введите название"
							maxLength={150}
							required
						/>
					</div>

					<div className={s.marginWrapper}>
						<StringField
							name="address"
							label="Место проведения"
							placeholder="Введите адрес или ссылку"
							maxLength={400}
						/>
					</div>

					<div className={s.row}>
						<SelectSingleWithFilterField
							options={allCategories ? formCategoriesForSelect(allCategories) : []}
							name="category"
							label="Категория"
							placeholder="Выберете из списка"
							// required
						/>

						<DateAndTimeField
							dateName={'startDate'}
							timeName={'startTime'}
							label={'Дата и время начала'}
							minDate={TODAY_DATE}
							required
						/>

						<DateAndTimeField
							dateName={'endDate'}
							timeName={'endTime'}
							label={'Дата и время окончания'}
							minDate={minEndDate}
							required
						/>
					</div>

					<TextAreaField
						name="description"
						label="Описание"
						placeholder="Введите описание"
						characterLimit={500}
					/>
				</FormProvider>
			</form>
		</div>
	);
};
