import React, { useEffect } from 'react';
import { office_serviceAPI } from 'src/app/redux/queries/office-service/office_serviceAPI';
import { OfficeRoom } from 'src/app/redux/state/office/office/types';
import { useAppSelector } from 'src/app/redux/utils';
import { routerPath } from 'src/app/router/paths';
import { ReactComponent as DeleteSVG } from 'src/shared/assets/svg_icons/action/delete.svg';
import { ReactComponent as EditSVG } from 'src/shared/assets/svg_icons/action/edit.svg';
import { ConditionalRendering } from 'src/shared/providers';
import { DropDownMenu } from 'src/shared/ui/DropDownMenu/DropDownMenu';
import { useRoomEdit } from '../../../../offices/ui/_pages/SingleOfficePage/Content/RoomsContent/hooks/useRoomEdit';
import { RoomsList } from '../../../../offices/ui/_pages/SingleOfficePage/Content/RoomsContent/RoomsList/RoomsList';
import s from './BookingRoomsList.module.scss';
import { FiltersBooking } from 'src/pages/booking/types';
import { useNavigate } from 'react-router-dom';

interface Props {
	officeId: string;
	params: FiltersBooking;
}

export const BookingRoomsList: React.FC<Props> = props => {
	const {
		officeId, //
		params,
	} = props;

	const navigate = useNavigate();

	const toBookingTable = (room: OfficeRoom) => {
		const bookingUrl = `${routerPath.booking.page}/${room.room.officeId}/${room.room.id}`;
		navigate(bookingUrl);
	};

	// * API
	const [editRoom] = office_serviceAPI.usePatchRoomEditMutation();
	const [findRooms, { currentData: roomsData, isLoading: isRoomsLoading }] = office_serviceAPI.useLazyGetRoomFindQuery();

	const rooms = roomsData?.body || [];

	// * Initial
	useEffect(() => {
		if (!officeId) return;

		findRooms({
			skipcount: 0,
			takecount: 100,
			officeId,
			...params,
		})
			.unwrap()
			.catch(err => console.log('error: ', err));
	}, [officeId, params]);

	// * Selectors
	const userInfo = useAppSelector(state => state.user_service.user.userInfo);

	const sortedRooms = [...rooms]
		.sort((a, b) => {
			return a.name.toLocaleLowerCase().localeCompare(b.name.toLowerCase());
		})
		.sort((a, b) => {
			return a.roomType.bookingRule === 'PartialBooking' ? 1 : -1;
		});

	// * Prepared variables
	const roomsPrepared = sortedRooms
		.filter(room => room.isActive === true)
		.map(item => ({
			room: { ...item },
			workspaces: [],
		}));
	const isAdmin = userInfo?.user.isAdmin;

	// * Create room
	const { openRoomForm } = useRoomEdit({ officeId });

	if (!officeId && roomsPrepared.length === 0) return null;

	return (
		<ConditionalRendering
			initialLoading={isRoomsLoading}
			isSuccessful={roomsPrepared.length > 0}
			LoadedResult={
				<div className={s.container}>
					<RoomsList
						rooms={roomsPrepared}
						onClick={toBookingTable}
						getDropDownMenu={
							!isAdmin
								? undefined
								: (roomId: string) => (
										<DropDownMenu
											items={[
												{
													title: 'Редактировать',
													icon: <EditSVG />,
													action: () => openRoomForm(roomId),
												},
												{
													title: 'Удалить',
													icon: <DeleteSVG />,
													action: () => {
														editRoom({
															roomId,
															body: [
																{
																	op: 'replace',
																	path: '/IsActive',
																	value: false,
																},
															],
														})
															.unwrap()
															.catch(err => console.log(err));
													},
												},
											]}
										/>
									)
						}
					/>
				</div>
			}
		/>
	);
};
