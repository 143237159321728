import React from 'react';
import { CategoryInfo } from 'src/app/redux/queries/event-service/types/types';
import { getCategoryInfo } from '../../../../lib/getCategoryInfo/getCategoryInfo';
import s from './CalendarEvent.module.scss';

interface Props {
	category: CategoryInfo[];
}

export const CalendarEvent: React.FC<Props> = ({ category }) => {
	const { color, name } = getCategoryInfo(category);

	return (
		<div
			className={s.event}
			style={{ backgroundColor: color }}
		>
			{name}
		</div>
	);
};
