import { FloatingFocusManager } from '@floating-ui/react';
import cn from 'classnames';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { ReactComponent as ArrowDownSVG } from 'src/shared/assets/svg/navigation/Arrow_down_v1.svg';
import { useFloatingWrapper } from 'src/shared/hooks/useFloatingWrapper';
import { formatToUpperFirstLetter } from 'src/shared/lib/string';
import s from './MonthPickerHeader.module.scss';

interface Props extends Omit<ReactDatePickerProps, 'onChange' | 'showMonthYearPicker' | 'showFullMonthYearPicker'> {
	selectedDate: Date;
	setSelectedDate: (date: Date) => void;
	fontSize?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

export const MonthPickerHeader: React.FC<Props> = props => {
	const { selectedDate, setSelectedDate, fontSize = 'h1', className, ...restProps } = props;

	const [isOpen, setIsOpen] = useState(false);

	const handleOnChange = (date: Date | null) => {
		setIsOpen(!isOpen);
		date && setSelectedDate(date);
	};

	const getDateString = () => {
		const luxonDate = DateTime.fromJSDate(selectedDate);
		return `${luxonDate ? `${formatToUpperFirstLetter(luxonDate.setLocale('ru').monthLong as string)} ${luxonDate.year}` : ''}`;
	};

	// * Floating
	const { floatingStyles, refs, context, getReferenceProps, getFloatingProps, headingId } = useFloatingWrapper(isOpen, setIsOpen);

	// * Render
	return (
		<>
			<div className={cn(s.container, className)}>
				<div
					ref={refs.setReference}
					{...getReferenceProps()}
					onClick={event => {
						event.preventDefault();
						setIsOpen(prevState => !prevState);
					}}
				>
					<button className={cn(s.button, s[`button_${fontSize}`])}>
						{getDateString()}
						<ArrowDownSVG />
					</button>
				</div>
			</div>

			{isOpen && (
				<FloatingFocusManager
					context={context}
					modal={false}
				>
					<div
						ref={refs.setFloating}
						style={{
							...floatingStyles,
							zIndex: 'var(--z-index-floating)',
						}}
						aria-labelledby={headingId}
						{...getFloatingProps()}
					>
						<DatePicker
							{...restProps}
							locale={'ru'}
							selected={selectedDate}
							onChange={handleOnChange}
							showMonthYearPicker
							showFullMonthYearPicker
							inline
						/>
					</div>
				</FloatingFocusManager>
			)}
		</>
	);
};
