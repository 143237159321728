import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetGroupsByGroupIdSubgroupsQuery } from 'src/app/redux/queries/vacation-service/vacation_serviceAPI';
import { LineTabs } from 'src/shared/ui/_tabs/lineTabs';
import s from '../../styles/Content.module.scss';
import { TabCategories } from '../TabCategories';
import { TabGroups } from '../TabGroups';
import { TabTeams } from '../TabTeams';
import { TabUsers } from '../TabUsers';

interface Props {
	isCustom: boolean;
}

export const Content: FC<Props> = props => {
	const { isCustom } = props;
	const { groupId } = useParams() as { groupId: string };

	// * API
	const { data: subGroupsData } = useGetGroupsByGroupIdSubgroupsQuery({
		groupId,
	});

	const subGroups = subGroupsData?.body || [];

	// * TABS
	const TABS = [
		{
			id: 'users',
			name: 'Список сотрудников',
			visible: isCustom,
		},
		{
			id: 'categories',
			name: 'Категории',
			visible: true,
		},
		{
			id: 'groups',
			name: 'Группы',
			visible: true,
		},
		{
			id: 'teams',
			name: 'Команды',
			visible: true,
		},
	].filter(item => item.visible);

	const [activeTabId, setActiveTabId] = useState<string>(TABS[0].id);

	// * Render
	return (
		<div>
			<LineTabs
				className={s.tabs}
				tabs={TABS}
				activeTabId={activeTabId}
				setActiveTabId={setActiveTabId}
			/>

			{activeTabId === 'users' && <TabUsers />}
			{activeTabId === 'categories' && <TabCategories data={subGroups.filter(item => item.type === 0)} />}
			{activeTabId === 'groups' && <TabGroups data={subGroups.filter(item => item.type === 1)} />}
			{activeTabId === 'teams' && <TabTeams data={subGroups.filter(item => item.type === 2)} />}
		</div>
	);
};
