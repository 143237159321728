import { company_serviceAPI } from 'src/app/redux/queries/company-service/company_serviceAPI';
import { PatchCompanyUserDocument } from 'src/app/redux/queries/company-service/types/types';
import { position_serviceAPI } from 'src/app/redux/queries/position-service/position_serviceAPI';
import { rights_serviceAPI } from 'src/app/redux/queries/rights-service/rights_serviceAPI';
import { Operation } from 'src/app/redux/queries/user-service/types/types';
import { user_serviceAPI } from 'src/app/redux/queries/user-service/user_serviceAPI';
import { dateToBackendISO, isDateTheSame } from 'src/shared/lib/date';
import { DefaultValues } from '../types';

export const useEditUser = (userId: string, prevValues: DefaultValues, onCloseModal: () => void) => {
	const promises: any[] = [];

	// * API
	const [editUser, { isLoading: isEditLoading1 }] = user_serviceAPI.usePatchUserEditMutation();
	const [editPosition, { isLoading: isEditLoading2 }] = position_serviceAPI.useEditPositionUserMutation();
	const [createPosition, { isLoading: isCreateLoading }] = position_serviceAPI.useCreatePositionMutation();
	const [editRole, { isLoading: isEditLoading3 }] = rights_serviceAPI.useEditUserRoleMutation();
	const [editContract, { isLoading: isEditLoading4 }] = company_serviceAPI.useEditCompanyUserMutation();
	const [getUser] = user_serviceAPI.useLazyGetUserGetQuery();

	const isEditLoading = isEditLoading1 || isEditLoading2 || isCreateLoading || isEditLoading3 || isEditLoading4;

	// * Submit
	const onEditSubmit = (data: DefaultValues) => {
		const {
			firstName,
			lastName,
			isAdmin,
			birthday,
			gender,
			contract,
			contractByTerm,
			middleName,
			position,
			rate,
			role,
			startWorkingDate,
			visibilityBirthday,
			businessHoursFrom,
			businessHoursTo,
			dateEndContract,
			dateEndProbation,
			probation,
			entitledVacationDays,
		} = data;

		const preparedArgs: Operation[] = [];

		// - diff
		if (firstName !== prevValues.firstName) {
			preparedArgs.push({
				op: 'replace',
				path: '/FirstName',
				value: firstName,
			});
		}

		if (lastName !== prevValues.lastName) {
			preparedArgs.push({
				op: 'replace',
				path: '/LastName',
				value: lastName,
			});
		}

		if (middleName !== prevValues.middleName) {
			preparedArgs.push({
				op: 'replace',
				path: '/MiddleName',
				value: middleName,
			});
		}

		if (gender.id !== prevValues.gender.id) {
			preparedArgs.push({
				op: 'replace',
				path: '/GenderId',
				value: gender.id,
			});
		}

		if (prevValues.businessHoursFrom !== businessHoursFrom) {
			preparedArgs.push({
				op: 'replace',
				path: '/BusinessHoursFromUtc',
				value: businessHoursFrom,
			});
		}

		if (prevValues.businessHoursTo !== businessHoursTo) {
			preparedArgs.push({
				op: 'replace',
				path: '/BusinessHoursToUtc',
				value: businessHoursTo,
			});
		}

		if (prevValues.entitledVacationDays !== entitledVacationDays) {
			preparedArgs.push({
				op: 'replace',
				value: entitledVacationDays,
				path: '/EntitledVacationDays',
			});
		}

		const isDateSame = birthday && prevValues.birthday ? isDateTheSame(birthday, prevValues.birthday) : birthday === prevValues.birthday;

		if (!isDateSame) {
			preparedArgs.push({
				op: 'replace',
				path: '/DateOfBirth',
				value: birthday ? dateToBackendISO(birthday) : null,
			});
		}

		if (visibilityBirthday.id !== prevValues.visibilityBirthday.id) {
			preparedArgs.push({
				op: 'replace',
				path: '/BirthType',
				value: visibilityBirthday.id,
			});
		}

		if (isAdmin !== prevValues.isAdmin) {
			preparedArgs.push({
				op: 'replace',
				path: '/IsAdmin',
				value: isAdmin,
			});
		}

		const preparedCompanyArgs: PatchCompanyUserDocument[] = [];

		if (contract.id !== prevValues.contract.id) {
			preparedCompanyArgs.push({
				op: 'replace',
				path: '/contractsubjectid',
				value: contract.id,
			});
		}

		if (contractByTerm.id !== prevValues.contractByTerm.id) {
			preparedCompanyArgs.push({
				op: 'replace',
				path: '/contracttermtype',
				value: contractByTerm.id,
			});
		}

		if (rate.id !== prevValues.rate.id) {
			preparedCompanyArgs.push({
				op: 'replace',
				path: '/rate',
				value: Number(rate.id),
			});
		}

		const isStartWorkingDateSame =
			startWorkingDate && prevValues.startWorkingDate ? isDateTheSame(startWorkingDate, prevValues.startWorkingDate) : startWorkingDate === prevValues.startWorkingDate;

		if (!isStartWorkingDateSame && startWorkingDate) {
			preparedCompanyArgs.push({
				op: 'replace',
				path: '/startworkingat',
				value: dateToBackendISO(startWorkingDate),
			});
		}

		const isDateEndContractSame =
			dateEndContract && prevValues.dateEndContract ? isDateTheSame(dateEndContract, prevValues.dateEndContract) : dateEndContract === prevValues.dateEndContract;

		if (!isDateEndContractSame) {
			preparedCompanyArgs.push({
				op: 'replace',
				path: '/endworkingat',
				value: dateEndContract ? dateToBackendISO(dateEndContract) : dateEndContract,
			});
		}

		const isDateEndProbationSame =
			dateEndProbation && prevValues.dateEndProbation ? isDateTheSame(dateEndProbation, prevValues.dateEndProbation) : dateEndProbation === prevValues.dateEndProbation;

		if (probation && !isDateEndProbationSame && dateEndProbation) {
			preparedCompanyArgs.push({
				op: 'replace',
				path: '/probation',
				value: dateToBackendISO(dateEndProbation),
			});
		} else if (!probation && prevValues.dateEndProbation) {
			preparedCompanyArgs.push({
				op: 'replace',
				path: '/probation',
				value: null,
			});
		}

		// - promises
		preparedArgs.length > 0 &&
			promises.push(
				editUser({
					userId,
					body: preparedArgs,
				}).unwrap(),
			);

		if (position.id !== prevValues.position.id) {
			if (!position.id && position.name) {
				promises.push(
					createPosition({
						createPositionRequest: {
							name: position.name,
						},
					})
						.unwrap()
						.then(res => {
							const posId = res.body;

							posId &&
								editPosition({
									createPositionUserRequest: {
										userId,
										positionId: posId,
									},
								})
									.unwrap()
									.catch(err => console.log(err));
						}),
				);
			} else {
				promises.push(
					editPosition({
						createPositionUserRequest: {
							userId,
							positionId: position.id,
						},
					}).unwrap(),
				);
			}
		}

		if (role.id !== prevValues.role.id) {
			promises.push(
				editRole({
					editUserRoleRequest: {
						userId,
						roleId: role.id,
					},
				}).unwrap(),
			);
		}

		preparedCompanyArgs.length > 0 &&
			promises.push(
				editContract({
					userId,
					editCompanyUserRequest: preparedCompanyArgs,
				}).unwrap(),
			);

		Promise.allSettled(promises)
			.then(() => {
				promises.length > 0 &&
					getUser({
						userid: userId,
						includecurrentavatar: true,
						includecommunications: true,
						includecompany: true,
						includedepartment: true,
						includeoffice: true,
						includeposition: true,
						includerole: true,
						locale: 'ru',
					});
				onCloseModal();
			})
			.catch(err => console.log('Error: ', err));
	};

	return { onEditSubmit, isEditLoading };
};
