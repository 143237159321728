import React from 'react';
import { project_serviceAPI } from 'src/app/redux/queries/project-service/project_serviceAPI';
import { LoaderCircle } from 'src/shared/ui/_loaders/LoaderCircle/LoaderCircle';
import { PageDecorator } from 'src/app/decorators/page_decorators/PageDecorator/PageDecorator';
import { ColorCircle } from 'src/entities/ColorCircle';
import { PageTitle } from 'src/entities/PageTitle/PageTitle';
import { useRights } from 'src/shared/hooks/useRights';
import { Status } from 'src/pages/project_service_pages/consts/status';
import { FormButton } from 'src/pages/project_service_pages/ui/ProjectPage/ui/FormButton/FormButton';
import { ProjectFilters } from 'src/pages/project_service_pages/ui/ProjectPage/ui/ProjectFilters/ProjectFilters';
import { ProjectsList } from 'src/pages/project_service_pages/ui/ProjectPage/ui/ProjectsList/ProjectsList';
import { EmptyData } from 'src/shared/ui/EmptyData/EmptyData';
import s from './ProjectPage.module.scss';
import { SelectedCount } from 'src/entities/_typography/SelectedCount';

export const ProjectPage = () => {
	// * Rights
	const projectsRightsOrAdmin = useRights(2);

	// * API
	const [findProjects, { data, isLoading }] = project_serviceAPI.useLazyFindProjectsQuery();

	//* Projects
	const projects =
		data?.body?.map(project => {
			const { id, name, shortName, customer, department, usersCount, status } = project;

			return {
				id,
				name: name || '-',
				shortName: shortName || '-',
				customer: customer || '-',
				department: department?.shortName || '-',
				usersCount: `${usersCount} чел`,
				status: (
					<React.Fragment key="1">
						<div className={s.status_wrapper}>
							<ColorCircle
								size="s"
								customColor={Status[status]?.color}
							/>
						</div>
					</React.Fragment>
				),
			};
		}) || [];

	// * Render
	return (
		<PageDecorator>
			<PageTitle
				title="Проекты"
				buttons={
					projectsRightsOrAdmin
						? [
								<React.Fragment key="0">
									<FormButton />
								</React.Fragment>,
							]
						: []
				}
			/>

			<div className={s.container}>
				<ProjectFilters findProjects={findProjects} />

				{!isLoading && data && (
					<SelectedCount
						title="Всего результатов"
						count={projects.length}
					/>
				)}

				{(!data || isLoading) && <LoaderCircle />}

				{projects.length > 0 && <ProjectsList projects={projects} />}
				{data && projects.length === 0 && !isLoading && <EmptyData />}
			</div>
		</PageDecorator>
	);
};
