import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { department_serviceAPI } from 'src/app/redux/queries/department-service/department_serviceAPI';
import { project_serviceAPI } from 'src/app/redux/queries/project-service/project_serviceAPI';
import { StatusOptions } from 'src/pages/project_service_pages/consts/status';
import { useCreateProject } from 'src/pages/project_service_pages/hooks/useCreateProject';
import { useEditProject } from 'src/pages/project_service_pages/hooks/useEditProject';
import { DefaultValuesForm } from 'src/pages/project_service_pages/types';
import { isoTimeStringToDate } from 'src/shared/lib/date';
import { Button } from 'src/shared/ui/_buttons/Button';
import { DateField } from 'src/shared/ui/_fields/DateField';
import { SelectSingleField } from 'src/shared/ui/_fields/SelectSingleField';
import { TextAreaField } from 'src/shared/ui/_fields/TextAreaField';
import * as yup from 'yup';
import s from './FormContent.module.scss';

interface Props {
	isEdit?: boolean;
	onCloseModal: () => void;
}

export const FormContent: React.FC<Props> = props => {
	const { isEdit, onCloseModal } = props;

	// * Router
	const params = useParams<{ projectId: string }>();
	const projectId: string = params.projectId as string;

	// * API
	const { data: projectData } = project_serviceAPI.useGetProjectQuery(
		{
			projectId: projectId,
			includeprojectusers: true,
			includedepartment: true,
		},
		{ skip: !projectId || !isEdit },
	);

	const { data: departmentData } = department_serviceAPI.useGetDepartmentFindQuery({
		skipcount: 0,
		takecount: 1000,
	});

	// - Deparment
	const departments = departmentData?.body?.map(item => ({
		id: item.id,
		name: item.shortName,
		fullName: item.name,
	})) || [{ id: '', name: '', fullName: '' }];

	// - Project info
	const projectInfo = projectData?.body;

	// * Form
	const defaultValues: DefaultValuesForm = {
		name: projectInfo?.name ? projectInfo.name : '',
		shortName: projectInfo?.shortName ? projectInfo.shortName : '',
		customer: projectInfo?.customer ? projectInfo.customer : '',
		department: {
			id: projectInfo?.department?.id || '',
			name: projectInfo?.department?.name || '',
		},
		status: StatusOptions.find(opt => opt.id === projectInfo?.status) || { id: 'Active', name: 'В работе' },
		startDateUtc: projectInfo?.startDateUtc ? isoTimeStringToDate(projectInfo.startDateUtc) : null,
		endDateUtc: projectInfo?.endDateUtc ? isoTimeStringToDate(projectInfo.endDateUtc) : null,
	};

	const schema = yup.object().shape({
		name: yup.string().nullable().max(150).required('Обязательное поле'),
		shortName: yup.string().nullable().max(40).required('Обязательное поле'),
		customer: yup.string().nullable().max(150),
		department: yup
			.object()
			.shape({
				id: yup.string().nullable(),
				name: yup.string().nullable(),
			})
			.nullable()
			.required('Выберите департамент'),
		status: yup
			.object()
			.shape({
				id: yup.string().nullable(),
				name: yup.string().nullable(),
			})
			.nullable()
			.required('Выберите статус'),
		startDateUtc: yup.date().required('Обязательное поле').nullable(),
		endDateUtc: yup
			.date()
			.nullable()
			.notRequired()
			.when('status.id', {
				is: (val: string) => val !== 'Active',
				then: yup.date().nullable().required('Обязательное поле'),
			}),
	});

	const formMethods = useForm({
		defaultValues,
		resolver: yupResolver(schema),
	});

	const { handleSubmit, watch } = formMethods;

	const startDateProject = watch('startDateUtc');

	// - submit
	const { onCreateSubmit, isCreateLoading } = useCreateProject(onCloseModal);
	const { onEditSubmit, isEditLoading } = useEditProject(projectId, defaultValues, onCloseModal);

	// * Render
	return (
		<form onSubmit={handleSubmit(isEdit ? onEditSubmit : onCreateSubmit)}>
			<FormProvider {...formMethods}>
				<div className={s.container}>
					<h2>{`${isEdit ? 'Редактирование' : 'Создание'} проекта`}</h2>

					<div className={s.wrapper}>
						<div className={s.wrapper_inner}>
							<TextAreaField
								name="name"
								label="Название"
								placeholder="Введите название проекта"
								size="small"
								characterLimit={150}
								required
							/>

							<TextAreaField
								name="shortName"
								label="Сокращенное название"
								placeholder="Введите сокращенное название проекта"
								size="tiny"
								characterLimit={40}
								required
							/>

							<TextAreaField
								name="customer"
								label="Заказчик "
								placeholder="Введите наименование заказчика"
								size="small"
								characterLimit={150}
							/>

							<SelectSingleField
								name="department"
								label="Департамент"
								placeholder="Выберите департамент из списка"
								options={departments}
								keyNames={{
									name: 'name',
									value: 'id',
									tooltipText: 'fullName',
								}}
								isNullable
							/>
						</div>

						<SelectSingleField
							name="status"
							label="Статус"
							options={StatusOptions}
						/>

						<DateField
							name="startDateUtc"
							label="Дата запуска"
							placeholderText="ДД ММ ГГГГ"
						/>

						<DateField
							name="endDateUtc"
							label="Дата завершения"
							placeholderText="ДД ММ ГГГГ"
							isClearable
							disabled={!startDateProject}
							minDate={startDateProject}
						/>
					</div>

					<div className={s.form_buttons}>
						<Button
							fixedWidth
							variant="tertiary"
							onClick={e => {
								e.preventDefault();
								onCloseModal();
							}}
						>
							Отмена
						</Button>

						<Button
							fixedWidth
							type="submit"
							isLoading={isCreateLoading || isEditLoading}
						>
							{isEdit ? 'Сохранить' : 'Создать'}
						</Button>
					</div>
				</div>
			</FormProvider>
		</form>
	);
};
