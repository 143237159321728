import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useLazyGetUserGetQuery } from 'src/app/redux/queries/user-service/user_serviceAPI';
import { useGetGroupsByGroupIdQuery } from 'src/app/redux/queries/vacation-service/vacation_serviceAPI';
import { CELL_GAP, COLUMNS_META, DIVIDER_WIDTH } from '../../consts';
import { MOCK_USERS } from '../../mock';
import { SortBy } from '../../types';
import { MonthSelector } from '../MonthSelector';
import { ScrollbarWrapper } from '../ScrollbarWrapper';
import { Table } from '../Table/ui/Table/Table';
import { UsersTable } from '../UsersTable';
import { User } from '../UsersTable/types';
import { useGetSelectedYear } from './useGetSelectedYear';
import s from './VacationTable.module.scss';
import { createFullName } from 'src/shared/lib/string';
import { getDaysSum } from 'src/shared/lib/date';

export const VacationTable: React.FC = () => {
	// * Routing
	const [searchParams] = useSearchParams();

	const groupId = searchParams.get('group');
	const year = searchParams.get('year');

	// * API
	const { data: groupData } = useGetGroupsByGroupIdQuery(
		{
			groupId: groupId ?? '',
		},
		{ skip: groupId === 'null' },
	);
	const group = groupData?.body;

	const [getUser, { isLoading }] = useLazyGetUserGetQuery();

	// * Users
	const [users, setUsers] = useState<User[]>([]);

	useEffect(() => {
		const groupUsers = group?.users ?? [];
		const subGroupUsers = (group?.subgroups ?? []).map(subGroup => subGroup.users ?? []).flat();
		const allUsers = [...groupUsers, ...subGroupUsers];

		const promises: ReturnType<typeof getUser>[] = [];

		if (allUsers.length > 0) {
			allUsers.forEach(user => {
				promises.push(
					getUser({
						userid: user.id,
						includecurrentavatar: false,
						includeavatars: false,
						includecover: false,
						includecommunications: false,
						includecompany: false,
						includedepartment: false,
						includeoffice: false,
						includeposition: false,
						includerole: false,
					}),
				);
			});
		}

		Promise.all(promises)
			.then(res => {
				const tableUsers: User[] = [];

				res.forEach((resItem, index) => {
					const resUser = resItem.data?.body?.user;
					const groupUser = allUsers[index];

					if (resUser) {
						tableUsers.push({
							id: resUser.id,
							name: createFullName({
								lastName: resUser.lastName ?? '',
								middleName: resUser.middleName ?? '',
								firstName: resUser.firstName ?? '',
							}),
							days: {
								total: 35, // ! HARDCODED
								left: groupUser.body.remainingVacationDays,
								used: groupUser.vacations //
									? groupUser.vacations.reduce((acc, value) => {
											const time = value.vacationBody?.time;
											let vacationTime = 0;
											if (time && time.endTime && time.startTime) {
												vacationTime = getDaysSum(new Date(time.startTime), new Date(time.endTime));
											}

											return acc + vacationTime;
										}, 0)
									: 0, //
							},
						});
					}
				});

				setUsers(tableUsers);
			})
			.catch(error => console.log('error', error));
	}, [group]);

	// * Sorting
	const [sortBy, setSortBy] = useState<{ ascending: boolean; by: SortBy } | null>(null);

	const sortedUsers = sortBy
		? users.sort((a, b) => {
				const aSortKey = a[sortBy.by];
				const bSortKey = b[sortBy.by];

				if (aSortKey && bSortKey) {
					if (sortBy.ascending) {
						return aSortKey > bSortKey ? 1 : -1;
					} else {
						return aSortKey < bSortKey ? 1 : -1;
					}
				}
				return 0;
			})
		: users;

	// * Year
	const selectedYear = useGetSelectedYear(Number(year));

	// * Table
	const leftSideWidth = COLUMNS_META.reduce((sum, current) => (sum += current.width + CELL_GAP), 0) + DIVIDER_WIDTH; // TODO: Заменить вычисления на реальное количество колонок.
	const tableRef = useRef<HTMLDivElement>(null);

	// * Render
	if (!selectedYear || !group) {
		return null;
	}

	return (
		<div className={s.container}>
			<ScrollbarWrapper
				usersTableWidth={leftSideWidth}
				usersCount={sortedUsers.length}
				ref={tableRef}
			>
				<div className={s.table}>
					<UsersTable
						users={sortedUsers}
						sortBy={sortBy}
						setSortBy={setSortBy}
					/>

					<Table
						users={sortedUsers}
						selectedYear={selectedYear}
					/>
				</div>
			</ScrollbarWrapper>

			<MonthSelector
				width={`calc(100% - ${leftSideWidth}rem)`}
				selectedYear={selectedYear}
				tableRef={tableRef}
			/>
		</div>
	);
};
