import { FC, useState } from 'react';
import { ConfirmModal } from 'src/entities/_modals/ConfirmModal/ConfirmModal';
import { ReactComponent as DeleteSVG } from 'src/shared/assets/svg_icons/action/delete.svg';
import { ReactComponent as EditSVG } from 'src/shared/assets/svg_icons/action/edit.svg';
import { DropDownMenu } from 'src/shared/ui/DropDownMenu/DropDownMenu';
import { useCommentsContext } from '../../context/useCommentsContext';

interface Props {
	commentId: string;
	editAction: () => void;
}

export const CommentDropDownMenu: FC<Props> = props => {
	const {
		commentId, //
		editAction,
	} = props;

	// * Context
	const { isLoading, deleteAction } = useCommentsContext();

	// * States
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const toggleDeleteModal = () => setShowDeleteModal(prevState => !prevState);

	// * Edit menu items
	const menuItems = [
		{
			isVisible: !!editAction,
			icon: <EditSVG />,
			title: 'Редактировать',
			action: () => editAction(),
		},
		{
			isVisible: !!deleteAction,
			icon: <DeleteSVG />,
			title: 'Удалить',
			action: () => toggleDeleteModal(),
		},
	].filter(item => item.isVisible);

	// * Render
	return (
		<>
			{showDeleteModal && (
				<ConfirmModal
					isOpen={showDeleteModal}
					onClose={toggleDeleteModal}
					title="Удаление комментария"
					description="Вы уверены, что хотите удалить комментарий?"
					primaryOnClick={() =>
						deleteAction &&
						deleteAction({
							commentId: commentId,
							successAction: toggleDeleteModal,
						})
					}
					isLoading={isLoading}
				/>
			)}

			<DropDownMenu items={menuItems} />
		</>
	);
};
