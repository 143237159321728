import cn from 'classnames';
import React, { FC, useEffect, useRef, useState } from 'react';
import { ReactComponent as LeftArrowSVG } from 'src/shared/assets/svg_icons/arrows/left_1.svg';
import { ReactComponent as RightArrowSVG } from 'src/shared/assets/svg_icons/arrows/right_1.svg';
import { LIST_OF_MONTHS_NAMES } from 'src/shared/consts/consts';
import { useWindowDimensions } from 'src/shared/hooks/useWindowDimensions';
import { CELL_GAP, CELL_SIZE, MONTH_GAP } from '../../consts';
import { SelectedYear } from '../../types';
import s from './MonthSelector.module.scss';

function isOverflown(element: HTMLDivElement) {
	return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
}

interface Props {
	width: string;
	selectedYear: SelectedYear;
	tableRef: React.RefObject<HTMLDivElement>;
}

export const MonthSelector: FC<Props> = props => {
	const {
		width, //
		selectedYear,
		tableRef,
	} = props;

	const ref = useRef<HTMLDivElement>(null);

	const { width: screenWidth } = useWindowDimensions();

	const [overflown, setOverflown] = useState<boolean>(false);
	const [scrollFinished, setScrollFinished] = useState<'left' | 'right' | null>(null);

	useEffect(() => {
		if (ref.current) {
			const currentOverflow = isOverflown(ref.current);
			if (currentOverflow !== overflown) {
				setOverflown(currentOverflow);
			}
		}
	}, [screenWidth]);

	const onArrowClick = (event: React.MouseEvent<SVGSVGElement, MouseEvent>, toLeft?: boolean) => {
		event.stopPropagation();

		const containerElement = ref.current;

		if (containerElement) {
			if (toLeft) {
				containerElement.scrollLeft -= 60;
			} else {
				containerElement.scrollLeft += 60;
			}

			if (containerElement.scrollLeft === 0) {
				setScrollFinished('left');
			} else if (containerElement.offsetWidth + Math.ceil(containerElement.scrollLeft) >= containerElement.scrollWidth) {
				setScrollFinished('right');
			} else {
				setScrollFinished(null);
			}
		}
	};

	const onMonthClick = (monthIndex: number) => {
		if (tableRef?.current) {
			if (monthIndex === 0) {
				tableRef.current.scrollLeft = 0;
			} else {
				let moveValue = 0;
				for (let i = 0; i < monthIndex; i++) {
					const daysInMonth = selectedYear.months[i].holidays.length;
					moveValue += daysInMonth * 16 * CELL_SIZE + (daysInMonth - 1) * 16 * CELL_GAP + MONTH_GAP * 16; // TODO: Replace 16 with current root font size
				}
				tableRef.current.scrollLeft = moveValue;
			}
		}
	};

	// * Render
	return (
		<div
			className={s.container}
			style={{ width: width }}
			ref={ref}
		>
			{overflown && (
				<LeftArrowSVG
					className={cn(s.arrow, s.arrow__left, scrollFinished === 'left' && s.arrow_disabled)}
					onClick={event => onArrowClick(event, true)}
				/>
			)}

			<div className={s.months}>
				{LIST_OF_MONTHS_NAMES.map((month, index) => (
					<span
						key={month}
						onClick={() => onMonthClick(index)}
					>
						{month}
					</span>
				))}
			</div>

			{overflown && (
				<RightArrowSVG
					className={cn(s.arrow, s.arrow__right, scrollFinished === 'right' && s.arrow_disabled)}
					onClick={event => onArrowClick(event)}
				/>
			)}
		</div>
	);
};
