import React, { ComponentPropsWithRef, ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { extractNestedError } from 'src/shared/lib/fields';
import { SelectSingle } from '../_inputs/selects/SelectSingle/SelectSingle';

export interface Option {
	id: string | Date | null; // TODO: Change id to value. Value type to generic.
	name: string | null;
	Icon?: ReactNode;
}

interface Props extends ComponentPropsWithRef<'div'> {
	name: string;
	label?: string;
	placeholder?: string;
	options: Option[] | string[];
	sortOptions?: boolean;
	keyNames?: {
		name: string;
		value: string;
		tooltipText?: string;
	};
	isNullable?: boolean;
	disabled?: boolean;
	required?: boolean;
}

export const SelectSingleField: React.FC<Props> = props => {
	const {
		name, //
		label,
		placeholder,
		options,
		sortOptions,
		keyNames = { name: 'name', value: 'id' },
		isNullable,
		disabled,
		required,
		...divProps
	} = props;

	const { control, setValue } = useFormContext();

	// * Render
	return (
		<Controller
			name={name}
			control={control}
			render={({ field, fieldState }) => (
				<SelectSingle
					{...divProps}
					{...field}
					label={label}
					placeholder={placeholder}
					keyNames={keyNames}
					selectedOption={field.value}
					setSelectedOption={value => setValue(field.name, value, { shouldValidate: true })}
					options={options}
					sortOptions={sortOptions}
					isNullable={isNullable}
					errorMessage={extractNestedError(fieldState.error)}
					disabled={disabled}
					required={required}
				/>
			)}
		/>
	);
};
