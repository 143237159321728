import { ReactComponent as DocSVG } from 'src/shared/assets/svg_icons/file/doc.svg';
import { ReactComponent as FolderSVG } from 'src/shared/assets/svg_icons/file/folder.svg';
import { ReactComponent as PhotoSVG } from 'src/shared/assets/svg_icons/file/photo.svg';

export const getIcon = (type: string) => {
	if (type === '.jpg' || type === '.jpeg' || type === '.png') {
		return <PhotoSVG />;
	} else if (type === '.zip') {
		return <FolderSVG />;
	} else {
		return <DocSVG />;
	}
};
