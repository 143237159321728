import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { PageDecorator } from 'src/app/decorators/page_decorators';
import { useGetGroupsByGroupIdQuery } from 'src/app/redux/queries/vacation-service/vacation_serviceAPI';
import { ConditionalRendering } from 'src/shared/providers';
import { Content } from '../Content/ui/Content';
import { Header } from '../Header';

export const GroupPage: FC = () => {
	const { groupId } = useParams() as { groupId: string };

	// * API
	const { data, isLoading } = useGetGroupsByGroupIdQuery({
		groupId: groupId,
	});

	const group = data?.body;

	// * Render
	return (
		<PageDecorator>
			<ConditionalRendering
				initialLoading={isLoading}
				isSuccessful={!!group && !isLoading}
				LoadedResult={
					group && (
						<>
							<Header
								title={group?.name ?? ''}
								isCustom={group?.isCustom}
								group={group}
							/>
							<Content isCustom={group?.isCustom} />
							{/* <div className={s.card}></div>
							<Button className={s.button}>asdf</Button> */}
						</>
					)
				}
			/>
		</PageDecorator>
	);
};
