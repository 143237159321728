import cn from 'classnames';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetUserBirthdayFindQuery, usePatchEventEditMutation } from 'src/app/redux/queries/event-service/event_serviceAPI';
import { CategoryInfo, Event, GroupedEvents, GroupedUsers } from 'src/app/redux/queries/event-service/types/types';
import { routerPath } from 'src/app/router/paths';
import { ConfirmModal } from 'src/entities/_modals/ConfirmModal/ConfirmModal';
import { ReactComponent as EditSVG } from 'src/shared/assets/svg_icons/action/edit.svg';
import { ReactComponent as ArchiveSVG } from 'src/shared/assets/svg_icons/file/archive.svg';
import { ReactComponent as UnArchiveSVG } from 'src/shared/assets/svg_icons/file/unarchive.svg';
import { useRights } from 'src/shared/hooks/useRights';
import { dateToBackendISO, dateToFullRuDateString, isDateInRange, isPastDate } from 'src/shared/lib/date';
import { createFullName } from 'src/shared/lib/string';
import { Button } from 'src/shared/ui/_buttons/Button';
import { ChipCategory } from 'src/shared/ui/_chips/ChipCategory/ChipCategory';
import { DropDownMenu } from 'src/shared/ui/DropDownMenu/DropDownMenu';
import { Heading } from 'src/shared/ui/Heading';
import { MONTHS } from '../../../../consts/consts';
import { compareEvents } from '../../../../lib';
import { getCategoryInfo } from '../../../../lib/getCategoryInfo/getCategoryInfo';
import { getMonthName } from '../../../../lib/getMonthName/getMonthName';
import { BirthdayModal } from '../BirthdayModal/BirthdayModal/BirthdayModal';
import s from './EventsContent.module.scss';

interface Props {
	events: Event[];
	activeTabId: string;
	showAllMonths: boolean;
	isCurrentYear: boolean;
}

const CURRENT_DATE = new Date();
const CURRENT_YEAR = CURRENT_DATE.getFullYear();
const CURRENT_MONTH = CURRENT_DATE.getMonth();

export const EventsContent: React.FC<Props> = props => {
	const {
		events, //
		activeTabId,
		showAllMonths,
		isCurrentYear,
	} = props;

	const navigate = useNavigate();
	const isArchive = activeTabId === 'archive';
	const isAdmin = useRights();

	const [eventId, setEventId] = useState('');
	const [selectedMonth, setSelectedMonth] = useState('');

	const [showArchiveModal, setShowArchiveModal] = useState(false);
	const toggleArchiveModal = () => setShowArchiveModal(prevState => !prevState);

	const [showBirthdayModal, setShowBirthdayModal] = useState(false);
	const toggleBirthdayModal = () => setShowBirthdayModal(prevState => !prevState);

	// * API
	const [editEvent, { isLoading }] = usePatchEventEditMutation();

	const { data: birthdayData } = useGetUserBirthdayFindQuery({
		startTime: dateToBackendISO(new Date(CURRENT_YEAR, 0, 1)) ?? '',
		endTime: dateToBackendISO(new Date(CURRENT_YEAR, 11, 31)) ?? '',
		skipcount: 0,
		takecount: 1000,
	});

	// * Menu
	const dropDownItems = [
		{
			isVisible: activeTabId === 'events',
			icon: <EditSVG />,
			title: 'Редактировать',
			action: (id?: string) => {
				if (!id) return;
				handleEdit(id);
			},
		},
		{
			isVisible: !isArchive,
			icon: <ArchiveSVG />,
			title: 'Архивировать',
			action: (id?: string) => {
				if (!id) return;
				setEventId(id);
				toggleArchiveModal();
			},
		},
		{
			isVisible: isArchive,
			icon: <UnArchiveSVG />,
			title: 'Восстановить',
			action: (id?: string) => {
				if (!id) return;
				handleEdit(id);
			},
		},
	].filter(item => item.isVisible);

	const onArchive = () => {
		editEvent({
			eventId: eventId,
			body: [
				{
					op: 'replace',
					path: '/IsActive',
					value: isArchive,
				},
			],
		})
			.then(() => toggleArchiveModal())
			.catch(e => console.log(e));
	};

	const handleClickPage = (id: string) => {
		navigate(`${routerPath.calendar.page}${routerPath.calendar.event}/${id}`);
	};

	const handleEdit = (id: string) => {
		navigate(`${routerPath.calendar.page}${routerPath.calendar.editEvent}/${id}`);
	};

	// - Группировка событий по месяцам
	const sortedEvents = [...events].sort(compareEvents);
	const groupedByMonth = sortedEvents.reduce<GroupedEvents>((acc, event) => {
		const month = getMonthName(event.date);

		if (!acc[month]) acc[month] = [];
		acc[month].push(event);
		return acc;
	}, {});

	let groupedEvents = MONTHS.map(month => ({
		month,
		events: groupedByMonth[month] || [],
	}));

	if (isCurrentYear && activeTabId === 'events') groupedEvents = groupedEvents.filter((_, index) => index >= CURRENT_MONTH);
	if (!showAllMonths) groupedEvents = groupedEvents.filter(group => group.events.length > 0);

	// - Сотрудники
	const users =
		birthdayData?.body?.map(item => {
			const { name, lastName, middleName } = item;
			return {
				...item,
				fullName: createFullName({ firstName: name, lastName, middleName: middleName || undefined }),
				day: Number(item.dateOfBirth.split('.')[0]),
				month: MONTHS[Number(item.dateOfBirth.split('.')[1]) - 1],
			};
		}) || [];

	// - Группировка сотрудников по месяцам
	const groupedUsers = users.reduce<GroupedUsers>((acc, user) => {
		const month = user.month;

		if (!acc[month]) acc[month] = [];
		acc[month].push(user);
		return acc;
	}, {});

	const renderCategories = (categories: CategoryInfo[]) => {
		const { name, color } = getCategoryInfo(categories);
		return (
			<ChipCategory
				name={name}
				color={color}
			/>
		);
	};

	const renderEventStatus = (start: Date, end: Date | null | undefined) => {
		if (end && isDateInRange(CURRENT_DATE, [start, end])) return <span className={s.info_status_active}>Уже идет</span>;
		if (isPastDate(start) && (end ? isPastDate(end) : true)) return <span className={s.info_status_past}>Прошло</span>;
	};

	// * Render
	return (
		<div>
			{showArchiveModal && (
				<ConfirmModal
					isOpen={showArchiveModal}
					onClose={toggleArchiveModal}
					title={'Архивировать'}
					description={`Вы действительно хотите архивировать выбранное событие?`}
					primaryText={'Архивировать'}
					primaryOnClick={onArchive}
					isLoading={isLoading}
				/>
			)}

			{showBirthdayModal && (
				<BirthdayModal
					month={selectedMonth}
					users={groupedUsers[selectedMonth]}
					isOpen={showBirthdayModal}
					onClose={toggleBirthdayModal}
				/>
			)}

			{groupedEvents.map(({ month, events }) => (
				<div
					key={month}
					className={s.month_section}
				>
					<div className={cn(s.flex_wrapper, s.month_section_title)}>
						<Heading level={3}>{month}</Heading>
						{!isArchive && groupedUsers[month]?.length > 0 && (
							<Button
								variant="tertiary"
								onClick={() => {
									setSelectedMonth(month);
									toggleBirthdayModal();
								}}
							>
								Дни рождения
							</Button>
						)}
					</div>

					{events.length > 0 ? (
						events.map(event => (
							<div
								key={event.id}
								className={s.card}
								onClick={() => handleClickPage(event.id)}
							>
								<div className={s.flex_wrapper}>
									<div>
										<div className={s.info}>
											<span className={s.info_date}>{dateToFullRuDateString(event.date)}</span>
											{renderEventStatus(event.date, event.endDate)}
										</div>
										<p className={s.title}>{event.name}</p>
									</div>

									{isAdmin && (
										<DropDownMenu
											items={dropDownItems}
											value={event.id}
										/>
									)}
								</div>

								<div>
									<p className={s.section_name}>Описание</p>
									<p>{event.description}</p>
								</div>

								<div>
									<p className={s.section_name}>Адрес</p>
									<p>-</p>
								</div>

								{event.eventsCategories && renderCategories(event.eventsCategories)}
							</div>
						))
					) : (
						<p className={s.no_events}>Нет событий в этом месяце.</p>
					)}
				</div>
			))}
		</div>
	);
};
