import { FC, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PageDecorator } from 'src/app/decorators/page_decorators';
import { Filters } from '../Filters';
import { Groups } from '../Groups';
import { Header } from '../Header';
import { SubHeader } from '../SubHeader';
import { VacationTable } from '../VacationTable';

export const VacationPage: FC = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const localStorageYear = localStorage.getItem('vacationYear');
	const localStorageGroup = localStorage.getItem('vacationGroupId');

	useEffect(() => {
		setSearchParams({
			group: localStorageGroup || 'null',
			year: localStorageYear || String(new Date().getFullYear().toString()),
		});
	}, []);

	// * Render
	return (
		<PageDecorator>
			<Header />
			<Groups />
			<SubHeader />
			<Filters />
			<VacationTable />
		</PageDecorator>
	);
};
