import { UserListItem } from 'src/app/redux/queries/vacation-service/types/types';
import { ReactComponent as CloseSVG } from 'src/shared/assets/svg_icons/action/close.svg';
import { CardsTable } from 'src/shared/ui/_tables/CardsTable';
import { Avatar } from 'src/shared/ui/Avatar/Avatar';
import { COLUMNS } from '../../consts/consts';
import s from './UsersTable.module.scss';

interface Props {
	users: UserListItem[];
	onDeleteUser: (userId: string) => void;
}

export const UsersTable: React.FC<Props> = props => {
	const {
		users, //
		onDeleteUser,
	} = props;

	const tableData = users.map(user => ({
		id: user.id,
		avatar: (
			<Avatar
				userAvatar={user.avatar}
				name={user.name}
			/>
		),
		user: {
			name: user.name,
			position: user.position ?? '',
		},
		department: user.department ?? '',
		extra: (
			<CloseSVG
				className={s.removeButton}
				onClick={event => {
					event.stopPropagation();
					onDeleteUser(user.id || '');
				}}
			/>
		),
	}));

	// * Render
	return (
		<>
			{users.length > 0 && (
				<CardsTable
					data={tableData}
					columns={COLUMNS}
					className={s.table}
				/>
			)}
		</>
	);
};
