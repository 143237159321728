import cn from 'classnames';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { ImageInfo } from 'src/app/redux/state/image/image/types';
import { ReactComponent as AddSVG } from 'src/shared/assets/svg/action/add.svg';
import { galleyFileToBase64 } from 'src/shared/lib/file/galleyFileToBase64/galleyFileToBase64';
import { formatBytes } from 'src/shared/lib/string';
import { v4 } from 'uuid';
import { LoaderCircle } from '../../../../_loaders/LoaderCircle/LoaderCircle';
import { GalleyFile } from '../../_types';
import { createImageInfoFromFile } from '../../_utils';
import s from './ImageDropDown.module.scss';

interface Props {
	currentFilesCount: number;
	setFiles: (newFiles: GalleyFile[]) => void;
	maxFiles: number;
	createImages?: (images: ImageInfo[]) => void;
	isLoading?: boolean;
	size: string;
}

export const ImageDrop: React.FC<Props> = props => {
	const {
		currentFilesCount, //
		setFiles,
		maxFiles,
		createImages,
		isLoading,
		size,
	} = props;

	const MAX_IMAGE_SIZE = 30000000; // bytes

	const [error, setError] = useState<string | null>(null);

	const { getRootProps, getInputProps } = useDropzone({
		accept: {
			'image/*': [],
		},
		// maxSize: 30000000, // 30mb
		maxSize: MAX_IMAGE_SIZE,
		onDrop: (acceptedFiles, fileRejections) => {
			error && setError(null);

			const files = acceptedFiles.map(file =>
				Object.assign(file, {
					id: `new_${v4()}`,
					parentId: null,
					preview: URL.createObjectURL(file),
				}),
			);

			if (currentFilesCount + files.length <= maxFiles) {
				!isLoading && setFiles(files);

				createImages &&
					Promise.all(files.map(file => galleyFileToBase64(file)))
						// TODO: Type values
						.then((values: any) => {
							const images = createImageInfoFromFile(values);
							createImages(images);
						});
			} else {
				setError(`Максимальное количество изображений ${maxFiles}`);
			}

			fileRejections.length > 0 &&
				fileRejections.forEach(file => {
					const error = file.errors.find(error => error.code === 'file-too-large');
					error && setError(`Файл ${file.file.name} превышает ${formatBytes(MAX_IMAGE_SIZE)}`);
				});
		},
	});

	// * Render
	return (
		<div
			className={cn(s.container, s[`size_${size}`])}
			aria-disabled={isLoading}
			{...getRootProps()}
		>
			<input {...getInputProps()} />
			<div className={s.main_text}>
				{isLoading ? <LoaderCircle /> : <AddSVG />}
				<span>Добавить фото</span>
			</div>
			{error && <span className={s.error}>{error}</span>}
		</div>
	);
};
