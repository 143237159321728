import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	useDeleteEventCategoryRemoveMutation,
	useDeleteFileRemoveMutation,
	useDeleteImageRemoveMutation,
	usePatchEventEditMutation,
	usePatchEventUserEditMutation,
	usePostEventCategoryCreateMutation,
	usePostEventCreateMutation,
	usePostEventUserCreateMutation,
	usePostImageCreateMutation,
} from 'src/app/redux/queries/event-service/event_serviceAPI';
import { FileInfo, ImageContent, ImageInfo, initialFilesImages } from 'src/app/redux/queries/event-service/types/types';
import { usePostFileCreateMutation } from 'src/app/redux/queries/file-service/file_serviceAPI';
import { useGetUserFindQuery } from 'src/app/redux/queries/user-service/user_serviceAPI';
import { actionsEventCalendar } from 'src/app/redux/state/event-calendar/slice';
import { EventCreation, UserListItem } from 'src/app/redux/state/event-calendar/types';
import { useAppDispatch, useAppSelector } from 'src/app/redux/utils';
import { routerPath } from 'src/app/router/paths';
import { SelectedCount } from 'src/entities/_typography/SelectedCount';
import { dateTimeStringToISO } from 'src/shared/lib/date';
import { createFullName } from 'src/shared/lib/string';
import { AddButton } from 'src/shared/ui/_buttons/AddButton';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { EventCreationHeader } from '../../../../../EventCreationHeader/EventCreationHeader';
import { PATHS, PathValues } from '../../../../consts/consts';
import { Form } from '../../../EventMainInfo/types';
import { AddUserMCContainer } from '../AddUserMCContainer';
import { UsersListContainer } from '../UsersListContainer';
import s from './AddUsersToEvent.module.scss';

interface Props {
	formName: string;
	eventId: string | undefined;
	files: File[];
	initialUsersIds: {
		id: string;
		userEventId: string;
	}[];
	initialValues: Form;
	initialFilesImages: initialFilesImages;
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
	setInitialFilesImages: React.Dispatch<
		React.SetStateAction<{
			images: ImageInfo[];
			files: FileInfo[];
		}>
	>;
}

export const AddUsersToEvent: React.FC<Props> = props => {
	const {
		formName, //
		eventId,
		files,
		initialUsersIds,
		initialValues,
		initialFilesImages,
		setLoading,
		setInitialFilesImages,
	} = props;

	const navigate = useNavigate();

	const [showModal, setShowModal] = useState(false);
	const toggleModal = () => setShowModal(prevState => !prevState);

	// * Selectors
	const event = useAppSelector(state => state.event_calendar.event_form.event);
	const eventImages = useAppSelector(state => state.event_calendar.event_form.eventImages);
	const selectedUsersInfo = useAppSelector(state => state.event_calendar.event_form.selectedUsersInfo).users;

	// * Actions
	const dispatch = useAppDispatch();
	const { setSelectedUsersInfo, resetEventForm } = actionsEventCalendar;

	// * API
	const [createEvent] = usePostEventCreateMutation();
	const [editEvent] = usePatchEventEditMutation();
	const [createFile] = usePostFileCreateMutation();
	const [deleteFile] = useDeleteFileRemoveMutation();
	const [createImage] = usePostImageCreateMutation();
	const [deleteImage] = useDeleteImageRemoveMutation();
	const [createCategory] = usePostEventCategoryCreateMutation();
	const [deleteCategory] = useDeleteEventCategoryRemoveMutation();
	const [createEventUsers] = usePostEventUserCreateMutation();
	const [editEventUser] = usePatchEventUserEditMutation();

	const { data } = useGetUserFindQuery({
		skipcount: 0,
		takecount: 1000,
		includepositions: true,
		includedepartments: true,
		isactive: true,
		includecurrentavatar: true,
	});

	const allCompanyUsers = data?.body ?? [];

	// - Выбираем только ту информацию которая нужна для списков пользователей.
	const allCompanyUsersList: UserListItem[] = allCompanyUsers.map(item => ({
		id: item.user.id,
		name: createFullName({ firstName: item.user.firstName, lastName: item.user.lastName, middleName: item.user.middleName ?? undefined }),
		position: item.positionUser?.name ?? '',
		department: item.departmentUser?.department.name,
		avatar: item.user.avatar,
	}));

	// - Только редактирования. Добавляем пользователей в состояние формы.
	useEffect(() => {
		if (initialUsersIds.length && allCompanyUsers.length) {
			dispatch(setSelectedUsersInfo(allCompanyUsersList.filter(user => initialUsersIds.find(init => user.id === init.id))));
		}
	}, [initialUsersIds, allCompanyUsers]);

	const separateFiles = () => {
		const initialFiles = initialFilesImages.files ?? [];
		const filesToPost = files ? files.filter(file => !initialFiles.some(initialFile => initialFile.name === file.name && initialFile.extension === file.type)) : [];
		const filesToDelete = initialFiles
			.filter(initialFile => !files.some(file => file.name === initialFile.name && file.type === initialFile.extension))
			.map(initialFile => initialFile.id);

		return { filesToDelete, filesToPost };
	};

	const separateImages = () => {
		const initialImages = initialFilesImages.images ?? [];
		const imagesToDelete = initialImages.filter(initialImage => !eventImages.some(image => image.id === initialImage.id)).map(initialImage => initialImage.id);
		const imagesToPost = eventImages
			? eventImages.filter(image => !initialImages.some(initialImage => initialImage.id === image.id)).map(({ name, content, extension }) => ({ name, content, extension }))
			: [];
		return { imagesToDelete, imagesToPost };
	};

	// Только измененные поля для edit
	const createBodyEdit = (event: EventCreation) => {
		const initial = {
			...initialValues,
			date: dateTimeStringToISO(initialValues?.startDate, initialValues?.startTime),
			endDate: dateTimeStringToISO(initialValues?.endDate, initialValues?.endTime),
			isActive: false,
		};

		const newEvent = {
			...event,
			isActive: !initialValues.isActive,
		};

		const body: { op: 'replace'; path: PathValues; value: any }[] = [];

		for (const key in PATHS) {
			const initialValue = initial[key as keyof Form] || '';
			const eventValue = newEvent[key as keyof EventCreation] || '';

			if (JSON.stringify(initialValue) !== JSON.stringify(eventValue)) {
				body.push({
					op: 'replace' as const,
					path: PATHS[key as keyof typeof PATHS],
					value: newEvent[key as keyof EventCreation],
				});
			}
		}

		return body;
	};

	const onPostImages = (images: ImageContent[], entityId: string) => {
		return createImage({
			createImagesRequest: {
				entityId,
				images,
			},
		}).unwrap();
	};

	const onDeleteImages = (imagesIds: string[], entityId: string) => {
		return deleteImage({
			removeImageRequest: {
				entityId,
				imagesIds,
			},
		}).unwrap();
	};

	const onPostFiles = (files: File[], entityId: string) => {
		const filesFormData = new FormData();
		for (const newFile of files) {
			filesFormData.append('uploadedFiles', newFile);
		}

		return createFile({
			createFilesRequest: {
				fileSource: 'Event',
				accessType: 'Public',
				entityId,
				uploadedFiles: filesFormData,
			},
		}).unwrap();
	};

	const onDeleteFiles = (filesIds: string[], entityId: string) => {
		return deleteFile({
			removeFilesRequest: {
				entityId,
				filesIds,
			},
		}).unwrap();
	};

	const onPostCategory = (categoriesIds: string[], eventId: string) => {
		return createCategory({
			createEventCategoryRequest: {
				eventId,
				categoriesIds,
			},
		}).unwrap();
	};

	const onDeleteCategory = (categoriesIds: string[], eventId: string) => {
		return deleteCategory({
			removeEventCategoryRequest: {
				eventId,
				categoriesIds,
			},
		}).unwrap();
	};

	const onCreateEventUsers = (users: { userId: string }[], eventId: string) => {
		createEventUsers({
			createEventUserRequest: {
				eventId,
				users,
			},
		}).unwrap();
	};

	const onDeleteEventUsers = (eventUserId: string) => {
		editEventUser({
			eventUserId,
			body: [
				{
					op: 'replace',
					value: 'Discarded',
					path: '/Status',
				},
			],
		}).unwrap();
	};

	const createPromises = (event: EventCreation, eventId: string) => {
		const promises = [] as any;

		// - Images
		const { imagesToDelete, imagesToPost } = separateImages();

		if (imagesToDelete.length > 0) {
			promises.push(onDeleteImages(imagesToDelete, eventId));
		}
		if (imagesToPost.length > 0) {
			promises.push(onPostImages(imagesToPost, eventId));
		}

		// - Files
		const { filesToDelete, filesToPost } = separateFiles();

		if (filesToDelete.length > 0) {
			promises.push(onDeleteFiles(filesToDelete, eventId));
		}

		if (filesToPost.length > 0) {
			promises.push(onPostFiles(filesToPost, eventId));
		}

		// - Users
		const newUserIds = selectedUsersInfo.filter(({ id }) => !initialUsersIds.find(user => user.id === id)).map(({ id }) => ({ userId: id }));
		const userIdsToDelete = initialUsersIds.filter(({ id }) => !selectedUsersInfo.find(user => user.id === id));

		if (newUserIds.length > 0) {
			promises.push(onCreateEventUsers(newUserIds, eventId));
		}

		for (const id of userIdsToDelete) {
			promises.push(onDeleteEventUsers(id.userEventId));
		}

		// - Categories
		const prevCategory = initialValues.category.id;
		const newCategory = event.categoriesIds[0];

		if (newCategory !== prevCategory) {
			let deleteCategoryPromise;
			if (prevCategory !== '') {
				// если изначально была выбрана категория
				deleteCategoryPromise = onDeleteCategory([prevCategory], eventId);
				promises.push(deleteCategoryPromise);
			}

			if (newCategory) {
				// добавление новой категории после удаления предыдушей (если предыдущая была)
				const createCategoryPromise = deleteCategoryPromise
					? deleteCategoryPromise.then(() => {
							return onPostCategory([newCategory], eventId);
						})
					: onPostCategory([newCategory], eventId);
				promises.push(createCategoryPromise);
			}
		}

		return Promise.all(promises)
			.then(() => {
				dispatch(resetEventForm());
				navigate(`${routerPath.calendar.page}${routerPath.calendar.event}/${eventId}`);
			})
			.catch(e => console.log(e))
			.finally(() => {
				setLoading(false);
				setInitialFilesImages({ images: [], files: [] });
			});
	};

	const onEdit = (event: EventCreation, eventId: string) => {
		setLoading(true);

		// - Main Info
		const body = createBodyEdit(event);

		if (body.length > 0) {
			editEvent({ eventId, body })
				.unwrap()
				.then(() => createPromises(event, eventId))
				.catch(e => {
					console.log(e);
					setLoading(false);
					return;
				});
		} else {
			createPromises(event, eventId);
		}
	};

	const onCreate = (event: EventCreation) => {
		setLoading(true);
		const promises = [];

		// - Main Info
		const createEventPromise = createEvent({
			createEventRequest: {
				name: event.name,
				address: event.address,
				description: event.description,
				date: event.date!,
				endDate: event.endDate,
				format: event.format,
				access: event.access,
				users: selectedUsersInfo.map(user => ({
					userId: user.id,
				})),
			},
		}).then(res => {
			const entityId = 'data' in res && res.data.body;
			return entityId;
		});
		promises.push(createEventPromise);

		// - Categories
		if (event.categoriesIds[0]) {
			const createCategoryPromise = createEventPromise.then(entityId => {
				if (!entityId) return;
				return onPostCategory(event.categoriesIds, entityId);
			});
			promises.push(createCategoryPromise);
		}

		// - Files
		if (files.length > 0) {
			const filesPromise = createEventPromise.then(entityId => {
				if (!entityId) return;
				return onPostFiles(files, entityId);
			});
			promises.push(filesPromise);
		}

		// - Images
		if (eventImages.length > 0) {
			const imagesPromise = createEventPromise.then(entityId => {
				if (!entityId) return;
				return onPostImages(eventImages, entityId);
			});
			promises.push(imagesPromise);
		}

		Promise.all(promises)
			.then(([entityId]) => {
				if (entityId) {
					navigate(`${routerPath.calendar.page}${routerPath.calendar.event}/${entityId}`);
				} else {
					navigate(`${routerPath.calendar.page}`);
				}
				dispatch(resetEventForm());
			})
			.catch(e => console.log(e))
			.finally(() => setLoading(false));
	};

	const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (eventId && event && selectedUsersInfo.length > 0) {
			onEdit(event, eventId);
		} else if (event && selectedUsersInfo.length > 0) {
			onCreate(event);
		}
	};

	// * Render
	return (
		<>
			{showModal && ( // ! Для очистки выбранных пользователей. Можно воспользоваться useImperativeHandle для доступа к setTempSelectedUsersIds.
				<ModalNewProto
					isOpen={showModal}
					onClose={toggleModal}
				>
					<AddUserMCContainer
						toggleModal={toggleModal}
						allUsers={allCompanyUsersList}
					/>
				</ModalNewProto>
			)}

			<form
				id={formName}
				onSubmit={onSubmit}
			/>
			<div className={s.title}>
				<EventCreationHeader title="Участники" />
			</div>

			<SelectedCount
				className={s.title}
				title="Всего участников"
				count={selectedUsersInfo.length ?? 0}
			/>

			<AddButton
				title="Пригласить участника"
				onClick={toggleModal}
			/>

			{selectedUsersInfo.length > 0 && <UsersListContainer users={selectedUsersInfo} />}
		</>
	);
};
