import { createApi } from '@reduxjs/toolkit/query/react';
import { ServiceName } from 'src/app/api/api_types';
import { baseQueryWithReAuth } from 'src/app/api/apiSlice';
import {
	DeleteCategoriesRemoveApiArg,
	DeleteClaimsRemoveApiArg,
	DeleteFilesRemoveApiArg,
	DeleteImagesApiArg,
	GetCategoriesApiArg,
	GetCategoriesByCategoryIdApiArg,
	GetClaimsApiArg,
	GetClaimsByClaimIdApiArg,
	GetClaimsByClaimIdCommentsApiArg,
	GetClaimsGetStatsApiArg,
	GetFilesApiArg,
	GetImagesApiArg,
	PatchCategoriesByCategoryIdApiArg,
	PatchClaimsByClaimIdApiArg,
	PostCategoriesApiArg,
	PostClaimsApiArg,
	PostClaimsByClaimIdCommentsApiArg,
	PostImagesApiArg,
	PutCategoriesByCategoryIdApiArg,
	PutClaimsByClaimIdApiArg,
} from './types/regTypes';
import {
	DeleteCategoriesRemoveApiResponse,
	DeleteFilesRemoveApiResponse,
	DeleteImagesApiResponse,
	DeleteClaimsRemoveApiResponse,
	GetCategoriesApiResponse,
	GetCategoriesByCategoryIdApiResponse,
	GetClaimsApiResponse,
	GetClaimsByClaimIdApiResponse,
	GetClaimsByClaimIdCommentsApiResponse,
	GetClaimsGetStatsApiResponse,
	GetFilesApiResponse,
	GetImagesApiResponse,
	PatchCategoriesByCategoryIdApiResponse,
	PatchClaimsByClaimIdApiResponse,
	PostCategoriesApiResponse,
	PostClaimsApiResponse,
	PostClaimsByClaimIdCommentsApiResponse,
	PostImagesApiResponse,
	PutCategoriesByCategoryIdApiResponse,
	PutClaimsByClaimIdApiResponse,
} from './types/resTypes';

const reducerPath = `${ServiceName.CLAIM_SERVICE}` as const;

export const claim_serviceAPI = createApi({
	baseQuery: baseQueryWithReAuth,
	reducerPath,
	tagTypes: ['Categories', 'Claims', 'Comments', 'Files', 'Images'],
	endpoints: build => ({
		postCategories: build.mutation<PostCategoriesApiResponse, PostCategoriesApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Categories`,
				method: 'POST',
				body: queryArg.createCategoryCommand,
			}),
			invalidatesTags: ['Categories'],
		}),
		getCategories: build.query<GetCategoriesApiResponse, GetCategoriesApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Categories`,
				params: {
					IsAscendingSort: queryArg.isAscendingSort,
					IncludeDeactivated: queryArg.includeDeactivated,
					NameIncludeSubstring: queryArg.nameIncludeSubstring,
					Color: queryArg.color,
					skipcount: queryArg.skipcount,
					takecount: queryArg.takecount,
				},
			}),
			providesTags: ['Categories'],
		}),
		removeCategories: build.mutation<DeleteCategoriesRemoveApiResponse, DeleteCategoriesRemoveApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Categories/${queryArg.categoryId}`,
				method: 'DELETE',
				body: queryArg,
			}),
			invalidatesTags: ['Categories'],
		}),
		patchCategoriesByCategoryId: build.mutation<PatchCategoriesByCategoryIdApiResponse, PatchCategoriesByCategoryIdApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Categories/${queryArg.categoryId}`,
				method: 'PATCH',
				body: queryArg.body,
			}),
			invalidatesTags: ['Categories'],
		}),
		getCategoriesByCategoryId: build.query<GetCategoriesByCategoryIdApiResponse, GetCategoriesByCategoryIdApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Categories/${queryArg.categoryId}`,
			}),
			providesTags: ['Categories'],
		}),
		putCategoriesByCategoryId: build.mutation<PutCategoriesByCategoryIdApiResponse, PutCategoriesByCategoryIdApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Categories/${queryArg.categoryId}`,
				method: 'PUT',
				body: queryArg.updateCategoryRequest,
			}),
			invalidatesTags: ['Categories'],
		}),

		postClaims: build.mutation<PostClaimsApiResponse, PostClaimsApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Claims`,
				method: 'POST',
				body: queryArg.createClaimCommand,
			}),
			invalidatesTags: ['Claims'],
		}),
		getClaims: build.query<GetClaimsApiResponse, GetClaimsApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Claims`,
				params: {
					IsAscendingSort: queryArg.isAscendingSort,
					NameIncludeSubstring: queryArg.nameIncludeSubstring,
					IncludeDeactivated: queryArg.includeDeactivated,
					CategoryId: queryArg.categoryId,
					Priority: queryArg.priority,
					Status: queryArg.status,
					DeadLine: queryArg.deadLine,
					CreatedBy: queryArg.createdBy,
					CreatedAtUtc: queryArg.createdAtUtc,
					skipcount: queryArg.skipcount,
					takecount: queryArg.takecount,
				},
			}),
			providesTags: ['Claims'],
		}),
		patchClaimsByClaimId: build.mutation<PatchClaimsByClaimIdApiResponse, PatchClaimsByClaimIdApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Claims/${queryArg.claimId}`,
				method: 'PATCH',
				body: queryArg.body,
			}),
			invalidatesTags: ['Claims'],
		}),
		getClaimsByClaimId: build.query<GetClaimsByClaimIdApiResponse, GetClaimsByClaimIdApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Claims/${queryArg.claimId}`,
			}),
			providesTags: ['Claims'],
		}),
		putClaimsByClaimId: build.mutation<PutClaimsByClaimIdApiResponse, PutClaimsByClaimIdApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Claims/${queryArg.claimId}`,
				method: 'PUT',
				body: queryArg.updateClaimRequest,
			}),
			invalidatesTags: ['Claims'],
		}),
		deleteClaimByClaimId: build.mutation<DeleteClaimsRemoveApiResponse, DeleteClaimsRemoveApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Claims/${queryArg.claimId}`,
				method: 'DELETE',
				body: queryArg.claimId,
			}),
			invalidatesTags: ['Claims'],
		}),
		getClaimsGetStats: build.query<GetClaimsGetStatsApiResponse, GetClaimsGetStatsApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Claims/getStats`,
				params: {
					PeriodStart: queryArg.periodStart,
					PeriodEnd: queryArg.periodEnd,
					DepartmentIds: queryArg.departmentIds,
					CategoryIds: queryArg.categoryIds,
					OutdatePeriod: queryArg.outdatePeriod,
				},
			}),
			providesTags: ['Claims'],
		}),
		postClaimsByClaimIdComments: build.mutation<PostClaimsByClaimIdCommentsApiResponse, PostClaimsByClaimIdCommentsApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Claims/${queryArg.claimId}/Comments`,
				method: 'POST',
				body: queryArg.createCommentRequest,
			}),
			invalidatesTags: ['Comments'],
		}),
		getClaimsByClaimIdComments: build.query<GetClaimsByClaimIdCommentsApiResponse, GetClaimsByClaimIdCommentsApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Claims/${queryArg.claimId}/Comments`,
				params: { IsAscendingSort: queryArg.isAscendingSort, skipcount: queryArg.skipcount, takecount: queryArg.takecount },
			}),
			providesTags: ['Comments'],
		}),
		getFiles: build.query<GetFilesApiResponse, GetFilesApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Files/${queryArg.entityid}`,
				params: { skipcount: queryArg.skipcount, takecount: queryArg.takecount },
			}),
			providesTags: ['Files'],
		}),
		deleteFilesRemove: build.mutation<DeleteFilesRemoveApiResponse, DeleteFilesRemoveApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Files/remove`,
				method: 'DELETE',
				body: queryArg.removeFilesCommand,
			}),
			invalidatesTags: ['Files'],
		}),
		postImages: build.mutation<PostImagesApiResponse, PostImagesApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Images`,
				method: 'POST',
				body: queryArg.createImageBody,
			}),
			invalidatesTags: ['Images'],
		}),
		getImages: build.query<GetImagesApiResponse, GetImagesApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Images`,
			}),
			providesTags: ['Images'],
		}),
		deleteImages: build.mutation<DeleteImagesApiResponse, DeleteImagesApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Images`,
				method: 'DELETE',
				body: queryArg.body,
			}),
			invalidatesTags: ['Images'],
		}),
	}),
});

export const {
	usePostCategoriesMutation,
	useGetCategoriesQuery,
	usePatchCategoriesByCategoryIdMutation,
	useGetCategoriesByCategoryIdQuery,
	usePutCategoriesByCategoryIdMutation,
	usePostClaimsMutation,
	useGetClaimsQuery,
	usePatchClaimsByClaimIdMutation,
	useGetClaimsByClaimIdQuery,
	usePutClaimsByClaimIdMutation,
	useGetClaimsGetStatsQuery,
	usePostClaimsByClaimIdCommentsMutation,
	useGetClaimsByClaimIdCommentsQuery,
	useGetFilesQuery,
	useDeleteFilesRemoveMutation,
	usePostImagesMutation,
	useGetImagesQuery,
	useRemoveCategoriesMutation,
	useDeleteImagesMutation,
	useDeleteClaimByClaimIdMutation,
	useLazyGetClaimsQuery,
	useLazyGetClaimsGetStatsQuery,
} = claim_serviceAPI;
