import { BirthdayUser } from 'src/app/redux/queries/event-service/types/types';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { Heading } from 'src/shared/ui/Heading';
import { SingleBirthdayUser } from '../SingleBirthdayUser/SingleBirthdayUser';
import s from './BirthdayModal.module.scss';

interface GroupedByDay {
	[key: number]: BirthdayUser[];
}

interface Props {
	month: string;
	users: BirthdayUser[];
	isOpen: boolean;
	onClose: () => void;
}

export const BirthdayModal: React.FC<Props> = props => {
	const {
		month, //
		users,
		isOpen,
		onClose,
	} = props;

	// Группировка сотрудников по дню рождения
	const grouped =
		users?.reduce<GroupedByDay>((acc, item) => {
			if (!acc[item.day]) {
				acc[item.day] = [];
			}
			acc[item.day].push(item);
			return acc;
		}, {}) || [];

	const groupedByDay = Object.entries(grouped).map(([day, users]) => ({
		day,
		users,
	}));

	// * Render
	return (
		<ModalNewProto
			width="s"
			isOpen={isOpen}
			onClose={onClose}
		>
			<Heading
				level={3}
				className={s.title}
			>
				Дни рождения
				<span className={s.title_month}> {month}</span>
			</Heading>

			<div className={s.wrapper}>
				{groupedByDay?.map(({ day, users }) => (
					<div key={day}>
						<p className={s.day}>{day}</p>
						{users.map((user: BirthdayUser) => (
							<SingleBirthdayUser
								key={user.userId}
								user={user}
							/>
						))}
					</div>
				))}
			</div>
		</ModalNewProto>
	);
};
