import { Avatar } from 'src/shared/ui/Avatar/Avatar';
import s from './Comment.module.scss';
import { Heading } from 'src/shared/ui/Heading';
import { ReactComponent as ReplySVG } from 'src/shared/assets/svg_icons/action/reply.svg';
import { ReactComponent as DeleteSVG } from 'src/shared/assets/svg_icons/action/delete.svg';
import { user_serviceAPI } from 'src/app/redux/queries/user-service/user_serviceAPI';
import { DropDownMenu } from 'src/shared/ui/DropDownMenu/DropDownMenu';
import { preparedData } from 'src/pages/claims/lib/preparedData';
import { backendISOUTCToLocalLuxon, tupleToIsoString } from 'src/shared/lib/date';
import { Hours, Minutes } from 'src/shared/lib/date/types';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { IconButton } from 'src/shared/ui/_buttons/IconButton';

interface Props {
	key: string;
	comment: {
		id: string;
		claimId: string;
		content?: string | null;
		createdAtUtc: string;
		createdBy: string;
		modifiedAtUtc?: string | null;
		modifiedBy?: string | null;
	};
}

export const Comment = (props: Props) => {
	const {
		key, //
		comment,
	} = props;
	const [isDeleteModal, setIsDeleteModal] = useState(false);

	// * API
	const { data: userData } = user_serviceAPI.useGetUserGetQuery({ userid: comment.createdBy, includecurrentavatar: true });
	const user = userData?.body?.user;

	// * Dropdown
	const DROP_DOWN_ITEM = [
		{
			icon: <DeleteSVG />,
			title: 'Удалить',
			action: (id?: string | null) => {
				if (!id) return;
				toggleModalForm();
				removeComment();
			},
		},
	];

	const toggleModalForm = () => {
		setIsDeleteModal(!isDeleteModal);
	};

	const removeComment = () => {
		console.log('remove');
	};

	const answerOnComment = () => {
		console.log('ansver');
	};

	const preparedTime = (dateFormatString: string | undefined, isBackend?: boolean) => {
		const formatDate = isBackend ? backendISOUTCToLocalLuxon(dateFormatString as string) : DateTime.fromISO(dateFormatString as string);
		const newTime = tupleToIsoString([formatDate.hour as Hours, formatDate.minute as Minutes]);

		return newTime;
	};

	return (
		<div
			key={key}
			className={s.comments_info}
		>
			<Avatar
				size="large"
				name={`${user?.lastName} ${user?.firstName}`}
				userAvatar={user?.avatar}
			/>
			<div className={s.comments_info_left}>
				<div className={s.date_time}>
					<Heading level={4}>{`${user?.lastName} ${user?.firstName}`}</Heading>
					<p className={s.time}>{preparedData(comment.createdAtUtc)}</p>
					<p className={s.time}>{preparedTime(comment.createdAtUtc, true)}</p>
					<IconButton
						Icon={<ReplySVG />}
						onClick={answerOnComment}
					/>
				</div>
				<p>{comment.content}</p>
			</div>
			<DropDownMenu
				items={DROP_DOWN_ITEM}
				value={comment.id}
			/>
		</div>
	);
};
