import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import cn from 'classnames';
import React from 'react';
import { FileInfo, ImageInfo } from 'src/app/redux/queries/event-service/types/types';
import { imageServiceActions } from 'src/app/redux/state/image/actions';
import { Gallery } from 'src/shared/ui/_galleries/Gallery';
import { Heading } from 'src/shared/ui/Heading';
import { COLUMNS_EVENT_DOCS } from './consts';
import s from './EventFiles.module.scss';

interface Props {
	images: ImageInfo[];
	files: FileInfo[];
}

export const EventFiles: React.FC<Props> = ({ images, files }) => {
	// * Actions
	const { getImage } = imageServiceActions.image.async;

	const Table = useReactTable({
		data: files,
		columns: COLUMNS_EVENT_DOCS,
		getCoreRowModel: getCoreRowModel(),
	});

	// * Render
	return (
		<>
			{images.length > 0 && (
				<div key={JSON.stringify(images)}>
					<Heading
						level={4}
						marginBottom="s"
					>
						Галерея
					</Heading>
					<Gallery
						allowedToEdit={false}
						images={images}
						fetchImage={(imageId: string) =>
							getImage({
								params: {
									imageId,
									source: 'Event',
								},
							})
						}
					/>
				</div>
			)}

			{files.length > 0 && (
				<div>
					<Heading
						level={4}
						marginBottom="s"
					>
						Документы
					</Heading>

					<div className={s.table}>
						{Table.getHeaderGroups().map(headerGroup => (
							<div
								key={headerGroup.id}
								className={s.table_row}
							>
								{headerGroup.headers.map(header => (
									<div
										key={header.id}
										className={s.table_header}
									>
										{flexRender(header.column.columnDef.header, header.getContext())}
									</div>
								))}
							</div>
						))}

						{Table.getRowModel().rows.map(row => (
							<div
								key={row.id}
								className={cn(s.table_row, s.table_card)}
							>
								{row.getVisibleCells().map(cell => (
									<div
										key={cell.id}
										className={s.table_cell}
									>
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</div>
								))}
							</div>
						))}
					</div>
				</div>
			)}
		</>
	);
};
