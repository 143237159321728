import { FC } from 'react';
import { Heading } from 'src/shared/ui/Heading';
import s from './PlannedVacationMC.module.scss';
import { ValidationRequirements } from 'src/shared/ui/ValidationRequirements';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { Button } from 'src/shared/ui/_buttons/Button';
import { StringField } from 'src/shared/ui/_fields/StringField';
import { TextInput } from 'src/shared/ui/_inputs/text_Inputs/TextInput';
import { DateField } from 'src/shared/ui/_fields/DateField';
import { DateRangeField } from 'src/shared/ui/_fields/DateRangeField';
import { getDaysSum } from 'src/shared/lib/date';
import { IconButton } from 'src/shared/ui/_buttons/IconButton';
import { ReactComponent as DeleteSVG } from 'src/shared/assets/svg_icons/action/delete.svg';

const NAME = 'vacations';

interface Props {
	toggleModal: () => void;
}

export const PlannedVacationMC: FC<Props> = props => {
	const { toggleModal } = props;

	const formMethods = useForm({
		defaultValues: {
			[`${NAME}`]: [
				{
					startDate: new Date(),
					endDate: new Date(),
				},
			],
		},
		// resolver: yupResolver(schema),
	});

	const { control } = formMethods;

	const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
		control,
		name: NAME,
	});

	const { handleSubmit, watch, setError, formState, clearErrors } = formMethods;

	const formValues = watch();

	console.log('formValues :>> ', formValues);

	const onSubmit = (values: any) => {
		console.log();
	};

	const onFieldAdd = () => {
		append({ startDate: new Date(), endDate: new Date() });
		// remove(0);
	};

	// * Render
	return (
		<div className={s.container}>
			<Heading
				className={s.heading}
				level={2}
				marginBottom="l"
			>
				Внести
				<br /> планируемый отпуск
			</Heading>

			<FormProvider {...formMethods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<ValidationRequirements
						className={s.requirements}
						requirements={[
							{
								text: 'Количество внесённых дней отпуска не может превышать количество доступных дней отпуска',
								isDone: true,
							},
							{
								text: 'Хотя бы один из интервалов отпуска должен быть не менее 14 дней',
								isDone: false,
							},
						]}
					/>

					<div className={s.available}>
						<div>
							<div className={s.available__label}>Доступно дней отпуска</div>
							<div className={s.available__value}>28 TODO</div>
						</div>

						<div>
							<div className={s.available__label}>Внесено дней отпуска</div>
							<div className={s.available__value}>28 TODO</div>
						</div>
					</div>

					<div className={s.fields}>
						{fields.map(({ id }, index) => {
							const startDate = formValues.vacations[index].startDate;
							const endDate = formValues.vacations[index].endDate;

							return (
								<div key={id}>
									<span className={s.interval__header}>
										<span className={s.interval__title}>{index + 1} интервал отпуска</span>
										<IconButton Icon={<DeleteSVG />} />
									</span>

									<DateRangeField
										className={s.interval__fields}
										labels={['Дата начала', 'Дата окончания']}
										startTimeName={`${NAME}.${index}.startDate`}
										endTimeName={`${NAME}.${index}.endDate`}
									/>
									<span className={s.interval__description}> Выбрано дней: {getDaysSum(startDate, endDate)}, в том числе 1 праздничный день, который не оплачивается.</span>
								</div>
							);
						})}
					</div>

					<Button
						variant="secondary"
						onClick={onFieldAdd}
						wide
					>
						Добавить интервал отпуска
					</Button>
				</form>
			</FormProvider>
		</div>
	);
};
