import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import cn from 'classnames';
import { COLUMNS, ParticipantsData } from '../consts';
import s from './ParticipantsTable.module.scss';

interface Props {
	data: ParticipantsData[];
}

export const ParticipantsTable: React.FC<Props> = ({ data }) => {
	const Table = useReactTable({
		data: data,
		columns: COLUMNS,
		getCoreRowModel: getCoreRowModel(),
	});

	// * Render
	return (
		<div className={s.table}>
			{Table.getHeaderGroups().map(headerGroup => (
				<div
					key={headerGroup.id}
					className={s.table_row}
				>
					{headerGroup.headers.map(header => (
						<div
							key={header.id}
							className={s.table_header}
						>
							{flexRender(header.column.columnDef.header, header.getContext())}
						</div>
					))}
				</div>
			))}

			{Table.getRowModel().rows.map(row => (
				<div
					key={row.id}
					className={cn(s.table_row, s.table_card)}
				>
					{row.getVisibleCells().map(cell => (
						<div key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
					))}
				</div>
			))}
		</div>
	);
};
